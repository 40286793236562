import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/blogs/Picture11.webp'
import Section6 from '../homepage/Section6'
import BlogNav from '../../components/BlogNav'
import FB from '../../assets/images/fb.png'
import Linkedin from '../../assets/images/linkedin.png'
import Twitter from '../../assets/images/x-twitter.png'
import { Helmet } from 'react-helmet'
import { GetSimilarBlogComponent } from '../../components/BlogsImages'

const Blog28122023 = () => {

  return (
    <>
      <Helmet>
          <title>Mastering Reviews and Ratings: Boost Your Restaurant's Reputation on DoorDash, UberEats, GrubHub, Google, and Yelp</title>
          <meta name='description' content="Master your restaurant's reputation on DoorDash, UberEats, GrubHub, Google and Yelp with Voosh. Enhance customer experience, boost ratings, and increase profits. Click now to take control of your online presence." />
          <meta name='keywords' content="AI-Generated Responses, DoorDash, Google, GrubHub, SEO Strategies, Uber Eats, Voosh, Yelp, Algorithm, Boost, Brand Reputation, Customer Experience, Customer Feedback, Customer Service, Customizable Templates, Dispute Management, Encourage Feedback, Food Quality, High Rating, High-Quality Photos, Menu Optimization, Online Presence, Online Reputation, Optimize Online Presence, Positive Reviews, Promotions Management, Ranking, Ratings, Reduce Costs, Respond To Customer Feedback, Restaurant's Reputation, Reviews, Save Time, Search Rankings, Social Media Engagement" />
      </Helmet>
      <div className="bg-white mv-overfloX-h blog4">
        <div className="ss-bdr-top"></div>
        <div className="f-width m-auto">
          <div className="d-flex s-nav-flex">
            <Link to={'/'}>
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M14.4793 7.5286L13.1444 6.19525L8.47195 1.52854C8.34678 1.40355 8.17703 1.33334 8.00004 1.33334C7.82305 1.33334 7.6533 1.40355 7.52813 1.52854L2.85572 6.19525L1.52075 7.5286C1.39916 7.65434 1.33188 7.82274 1.3334 7.99754C1.33492 8.17234 1.40512 8.33955 1.52888 8.46316C1.65263 8.58676 1.82005 8.65688 1.99506 8.6584C2.17007 8.65991 2.33868 8.59272 2.46457 8.47128L2.66015 8.27594V13.3333C2.66015 13.687 2.80079 14.0261 3.05115 14.2761C3.30151 14.5262 3.64106 14.6667 3.99512 14.6667H5.99758C6.17461 14.6667 6.34439 14.5964 6.46956 14.4714C6.59474 14.3464 6.66507 14.1768 6.66507 14V11.3333C6.66507 11.1565 6.73539 10.9869 6.86057 10.8619C6.98575 10.7369 7.15553 10.6666 7.33255 10.6666H8.66753C8.84456 10.6666 9.01433 10.7369 9.13951 10.8619C9.26469 10.9869 9.33501 11.1565 9.33501 11.3333V14C9.33501 14.1768 9.40534 14.3464 9.53052 14.4714C9.65569 14.5964 9.82547 14.6667 10.0025 14.6667H12.005C12.359 14.6667 12.6986 14.5262 12.9489 14.2761C13.1993 14.0261 13.3399 13.687 13.3399 13.3333V8.27594L13.5355 8.47128C13.6614 8.59272 13.83 8.65991 14.005 8.6584C14.18 8.65688 14.3474 8.58676 14.4712 8.46316C14.595 8.33955 14.6652 8.17234 14.6667 7.99754C14.6682 7.82274 14.6009 7.65434 14.4793 7.5286Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <Link to={'/blogs'}>
              <font className="nav-ss-t">Blogs</font>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <font>Mastering Reviews and Ratings: Boost Your Restaurant's Reputation on DoorDash, UberEats, GrubHub, Google, and Yelp</font>
          </div>
          <p className="sstory-head">Mastering Reviews and Ratings: Boost Your Restaurant's Reputation on DoorDash, UberEats, GrubHub, Google, and Yelp</p>
          <div className="row ss-s2 mb60px">
            <div className="col-lg-6 col-sm-12">
              <img className="v-ss-brand-img rounded" width='560' src={Img1} alt="Reviews and Ratings on DoorDash UberEats GrubHub Google and Yelp" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="d-flex align-items-center sub-row-ss">
                <p><strong>Posted on</strong> : 28 December 2023</p>
              </div>
              <div className="ss-card1">
                <p>Summary Highlights</p>
                <p className='text-black'>
                Master your restaurant's reputation on DoorDash, UberEats, GrubHub, Google and Yelp with Voosh. Enhance customer experience, boost ratings, and increase profits. Click now to take control of your online presence.
                </p>
              </div>
              <span id='1'></span>
            </div>
          </div>
        </div>
      </div>
      <BlogNav navdata={['Introduction','Understanding Reviews and Ratings','Strategies to Boost Reviews and Ratings','Voosh Review Manager','Other Voosh Features',"Optimizing Your Restaurant's Online Presence","Leveraging Voosh for Maximum Results","Conclusion","Key Voosh Resources"]} />
      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h'>
        <div className='ss-text-content'>
          <div className="text-row-ss" id='ProblemDescription'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">01</p>
              <p>Introduction</p>
            </div>
            <div className="t-h-bdr"></div>
            <span id='2'></span>
            <p className='rtss1'>Importance of reviews and ratings for restaurants on third-party platforms</p>
            <p className='rtss2'>Reviews and ratings play a crucial role in the success of restaurants on third-party platforms like DoorDash, UberEats, GrubHub, Google and Yelp. They influence customers' choices, impact search rankings, and contribute to a restaurant's online reputation.</p>
           <p className='rtss1'>Impact on customer experience and brand reputation</p>
           <p className='rtss2'>Positive reviews and high ratings not only attract more customers but also enhance the overall customer experience. They help build trust, establish credibility, and ultimately strengthen a restaurant's brand reputation in the competitive market.</p>
           <p className='rtss1'>Role of Voosh in managing and growing businesses on DoorDash, UberEats, GrubHub, Google and Yelp</p>
           <p className='rtss2'>Voosh offers comprehensive solutions for managing and growing businesses on third-party platforms. With features like dispute management, reviews and ratings, promotions management, and finance and reconciliation services, Voosh enables restaurants to improve their online presence and boost their profits.</p>
           </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">02</p>
              <p>Understanding Reviews and Ratings on DoorDash, UberEats, GrubHub, Google and Yelp</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>To effectively boost your restaurant's reputation on DoorDash, UberEats, GrubHub, Google and Yelp it is essential to understand how ratings and reviews work on each platform. Each platform has its unique algorithm that determines the ranking of restaurants, which is directly influenced by the reviews and ratings given by customers.</p>
            <p className='rtss1'>How ratings and reviews work on each platform</p>
            <p className='rtss2'>On DoorDash, customers can rate their overall experience with a restaurant and the delivery driver separately. The restaurant's rating is an average of all the ratings given by customers, which is displayed on the platform. Similarly, UberEats allows customers to rate the restaurant and the delivery partner separately. GrubHub, Google and Yelp on the other hand, focuses on the restaurant's overall star rating, which is calculated by averaging all customer reviews.</p>
            <p className='rtss1'>Factors affecting the algorithm and ranking</p>
            <p className='rtss2'>Several factors contribute to the algorithm and ranking of restaurants on each platform. These factors include the number of reviews, the average rating, the recency of reviews, and the response rate to customer feedback. Additionally, other factors such as order completion rate, delivery time, and operational optimization also play a role in the overall ranking.</p>
            <p className='rtss1'>Importance of maintaining a high rating and positive reviews</p>
            <span id='3'></span>
            <p className='rtss2'>Maintaining a high rating and positive reviews is crucial for any restaurant on third-party platforms. A high rating not only helps your restaurant stand out among the competition but also builds trust with potential customers. Positive reviews serve as social proof, encouraging more customers to try your restaurant. Furthermore, higher ratings and positive reviews can lead to better search rankings, increased visibility, and ultimately, more orders and revenue.</p>
          </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">03</p>
              <p>Strategies to Boost Reviews and Ratings</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>To improve your restaurant's reviews and ratings on DoorDash, UberEats, GrubHub, Google and Yelp it's essential to implement effective strategies that focus on enhancing the customer experience and encouraging feedback.</p>
            <p className='rtss1'>Providing Excellent Customer Service and Food Quality</p>
            <p className='rtss2'>The foundation of any successful restaurant is delivering exceptional customer service and high-quality food. By consistently meeting and exceeding customer expectations, you create a positive dining experience that fosters customer loyalty and leads to positive reviews. Train your staff to be attentive, accommodating, and efficient, and ensure that your menu offerings are fresh, delicious, and well-presented.</p>
            <p className='rtss1'>Encouraging Customers to Leave Reviews and Ratings</p>
            <p className='rtss2'>While many customers leave reviews and ratings voluntarily, it's essential to actively encourage feedback. You can do this by displaying signs at your restaurant, including reminders on receipts, or sending follow-up emails to customers who have placed online orders. Social media can also be an effective platform for engaging with customers and requesting feedback. Offering incentives, like discounts or freebies, for leaving a review can be another great motivator for customers to share their experience.</p>
            <p className='rtss1'>Responding to Customer Feedback and Addressing Concerns</p>
            <span id='4'></span>
            <p className='rtss2'>Actively monitoring and responding to customer feedback is a crucial aspect of managing your restaurant's online reputation. Thank your customers for their positive reviews and demonstrate that you value their opinions. When addressing negative feedback, remain professional, apologize for any shortcomings, and offer a solution to rectify the issue. By showing that you genuinely care about your customers' experiences and are committed to improvement, you can turn negative reviews into opportunities for growth and increased customer satisfaction.</p>
          </div>
          <div className="text-row-ss" id='Testimonial'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">04</p>
              <p>Voosh Review Manager: Enhancing the Management of Reviews and Ratings</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="rtss2">The Voosh Review Manager is a powerful tool that streamlines the management of reviews and ratings across multiple platforms, including DoorDash, Uber Eats, GrubHub, Google, and Yelp. By utilizing its advanced features, restaurants can effectively manage their online reputation and improve search rankings.</p>
            <p className='rtss1'>Integration with Google and Yelp</p>
            <p className='rtss2'>The Voosh Review Manager seamlessly integrates with Google and Yelp, allowing restaurants to monitor and respond to customer reviews across various platforms from a single dashboard. This comprehensive approach not only saves time but also ensures that no review goes unnoticed.</p>
            <p className='rtss1'>Customizable Templates and AI-Generated Responses</p>
            <p className='rtss2'>One of the standout features of the Voosh Review Manager is the ability to create customizable templates and use AI-generated responses. This feature enables businesses to maintain a consistent brand voice while addressing customer feedback efficiently and effectively.</p>
            <p className='rtss1'>Improving Search Rankings and Online Presence</p>
            <p className='rtss2'>By actively managing reviews and ratings with the Voosh Review Manager, restaurants can improve their search rankings and overall online presence. Positive reviews and high ratings help attract more customers, build trust, and ultimately drive more orders and revenue.</p>
            <p className='rtss1'>Benefits of Using Voosh Review Manager for Restaurants</p>
            <span id='5'></span>
            <p className="rtss2">Restaurants that use Voosh Review Manager can enjoy numerous benefits, including streamlined review management across multiple platforms, customizable templates, AI-generated responses, and an improved online presence. By leveraging these features, restaurants can enhance their brand reputation, boost customer experience, and ultimately achieve better results on third-party marketplaces like DoorDash, UberEats, GrubHub, Google and Yelp.</p>
          </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">05</p>
              <p>Other Voosh Features to Improve Restaurant Performance</p>
            </div>
            <div className="t-h-bdr"></div>
           <p className='rtss2'>Apart from the Review Manager, Voosh offers a range of features that cater to the diverse needs of restaurants operating on third-party platforms like DoorDash, UberEats, GrubHub, ezCater, Google, Yelp and DoorDash-Drive. These integrations aim to enhance restaurant performance by addressing various challenges related to disputes, reviews, finance, promotion, and decision-making.</p>
          <p className='rtss1'>Dispute Management for Recovering Lost Profits</p>
          <p className='rtss2'>Voosh's dispute management feature enables restaurants to automatically recover chargebacks from third-party marketplaces. By efficiently handling disputes and recouping lost profits, Voosh helps restaurants save money and boost their bottom line.</p>
          <p className='rtss1'>Finance and Reconciliation Services</p>
          <p className='rtss2'>Managing finances and reconciling transactions with third-party marketplaces can be a complex and time-consuming task. Voosh simplifies the process by offering finance and reconciliation services, ensuring accuracy and minimizing the risk of financial discrepancies.</p>
          <p className='rtss1'>Promotions Management for Boosting Sales and Visibility</p>
          <p className='rtss2'>Effective promotions are crucial for driving sales and increasing visibility on third-party platforms. Voosh's promotions management feature allows restaurants to easily create, manage, and analyze promotions across various marketplaces, helping them attract more customers and generate more revenue.</p>
          <p className='rtss1'>Real-time Data and Analytics for Informed Decision-Making</p>
          <span id='6'></span>
          <p className='rtss2'>Data-driven insights play a vital role in making informed decisions for your restaurant. Voosh provides real-time data and analytics, allowing restaurants to view and download delivery data and gain valuable insights into their performance. This information empowers restaurants to make strategic decisions and continuously improve their operations.</p>
        </div>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">06</p>
              <p>Tips for Optimizing Your Restaurant's Online Presence</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>Optimizing your restaurant's online presence is crucial for attracting more customers and increasing sales on third-party platforms like DoorDash, UberEats, GrubHub, ezCater, Google and Yelp. By focusing on high-quality photos, menu optimization, SEO strategies, and customer engagement, you can enhance your restaurant's visibility and reputation.</p>
            <p className='rtss1'>High-Quality Photos and Menu Optimization</p>
            <p className='rtss2'>Using high-quality photos and optimizing your menu play a significant role in attracting customers to your restaurant. Eye-catching images of your dishes not only make your menu more appealing but also give customers an idea of what they can expect from your restaurant. Additionally, optimizing your menu by categorizing items, highlighting popular dishes, and providing clear descriptions and pricing information can help improve the overall customer experience and boost your restaurant's online presence.</p>
            <p className='rtss1'>Utilizing SEO Strategies for Better Discoverability</p>
            <p className='rtss2'>Search engine optimization (SEO) is essential for making your restaurant easily discoverable on third-party platforms and search engines. By incorporating relevant keywords into your menu descriptions, restaurant profile, and online content, you can improve your search rankings and increase the likelihood of customers finding your restaurant. Also, consider creating a Google My Business listing and optimizing it with accurate information, images, and customer reviews to further enhance your online visibility.</p>
            <p className='rtss1'>Engaging with Customers on Social Media Platforms</p>
            <span id='7'></span>
            <p className='rtss2'>Social media platforms like Facebook, Instagram, and Twitter offer opportunities to engage with your customers and build a strong online community. By regularly sharing updates, promotions, and behind-the-scenes content, you can create a connection with your audience and encourage them to share their experiences at your restaurant. Responding to comments, messages, and reviews on social media and on third-party marketplaces can also help you build trust and loyalty, ultimately boosting your restaurant's online presence and reputation.</p>
        </div>
        <div className="text-row-ss">
          <div className="t-h-ss align-items-center">
            <p className="t-s pe-2">07</p>
            <p>Leveraging Voosh for Maximum Results</p>
          </div>
          <div className="t-h-bdr"></div>
          <p className='rtss2'>By utilizing Voosh's comprehensive suite of features and solutions, restaurants can achieve maximum results on third-party platforms like DoorDash, UberEats, GrubHub, ezCater, Google and Yelp. Voosh offers a range of benefits that focus on enhancing brand reputation, streamlining operations, and catering to restaurants of all sizes.</p>
          <p className='rtss1'>Enhancing Brand Reputation and Customer Experience</p>
          <p className='rtss2'>Voosh's innovative solutions, such as the Review Manager, enable restaurants to actively manage their online reputation, resulting in a better brand image and improved customer experience. The platform's seamless integration with DoorDash, UberEats, GrubHub, Google and Yelp allows for efficient monitoring and responding to customer reviews across multiple platforms, ultimately fostering trust and loyalty among customers.</p>
          <p className='rtss1'>Saving Time and Reducing Costs with a Single Dashboard</p>
          <p className='rtss2'>Voosh's single-dashboard approach helps restaurants save time and reduce costs by consolidating all their third-party platform operations in one place. The platform's features, such as dispute management, finance and reconciliation services, and promotions management, streamline various aspects of restaurant operations, resulting in higher efficiency and lower operational costs.</p>
          <p className='rtss1'>Catering to Restaurants of All Sizes, from Ghost Kitchens to Multi-Unit Establishments</p>
          <span id='8'></span>
          <p className='rtss2'>Voosh's flexible and scalable solutions cater to the diverse needs of restaurants of all sizes, from small ghost kitchens to large multi-unit establishments. By offering tailored features and insights that address the unique challenges faced by different types of restaurants, Voosh empowers businesses to optimize their performance and maximize profits on third-party platforms.</p>
        </div>

        <div className="text-row-ss">
          <div className="t-h-ss align-items-center">
            <p className="t-s pe-2">08</p>
            <p>Conclusion</p>
          </div>
          <div className="t-h-bdr"></div>
          <p className='rtss2'>In today's competitive restaurant industry, mastering reviews and ratings on DoorDash, UberEats, GrubHub, Google and Yelp is essential for success. Positive reviews and high ratings contribute to a strong online reputation, attract more customers, and ultimately drive revenue growth. Voosh's comprehensive solutions offer a one-stop platform for managing and growing businesses on third-party delivery platforms. With features like dispute management, reviews and ratings management, finance and reconciliation services, and promotions management, Voosh helps restaurants save time, reduce costs, and maximize profits.</p>
          <span id='9'></span>
          <p className='rtss2'>If you're looking to enhance your restaurant's performance on DoorDash, Uber Eats, GrubHub, and more, consider leveraging the power of Voosh. With a 30-day free trial, you can experience the revolutionary restaurant intelligence platform and witness its impact on your business firsthand. Don't miss this opportunity to streamline your restaurant operations and boost your online reputation. Try Voosh's 30-day free trial today!</p>
        </div>
         
         <div className="text-row-ss">
          <div className="t-h-ss align-items-center">
            <p className="t-s pe-2">09</p>
            <p>Key Voosh Resources</p>
          </div>
          <div className="t-h-bdr"></div>
          <p className='rtss2'></p>
          <ul>
            <li><Link to={'/'}>Voosh.ai</Link></li>
            <li><Link to={'/press'}>Voosh.ai/press</Link></li>
            <li><Link to={'/finance'}>Voosh.ai/finance</Link></li>
            <li><Link to={'/about-us'}>Voosh.ai/about-us</Link></li>
            <li><Link to={'https://dashboard.voosh.ai/'}>Dashboard.voosh.ai</Link></li>
            <li><Link to={'/review-rating'}>Voosh.ai/review-rating</Link></li>
            <li><Link to={'/product-features'}>Voosh.ai/product-features</Link></li>
            <li><Link to={'/analytics-insights'}>Voosh.ai/analytics-insights</Link></li>
            <li><Link to={'/success-stories'}>Voosh.ai/success-stories</Link></li>
          </ul>
          <p className='rtss2'>Visit these resources to learn more about Voosh's revolutionary restaurant intelligence platform and how it can help your business thrive on DoorDash, UberEats, GrubHub, ezCater, DoorDash-Drive, Google and Yelp. Don't forget to try Voosh's 30-day free trial and experience the benefits for yourself!</p>
        </div>

            <div className='text-row-ss'>
            <div className='rtss1 pb-3'>Don't forget to share this blog</div>
            <div className='d-flex' style={{gap:'24px'}}>
            <Link to={"https://www.facebook.com/sharer/sharer.php?u=https://voosh.ai/blogs/mastering-reviews-and-ratings-boost-your-restaurants-reputation-on-doordash-ubereats-and-grubhub/"}>
                <img src={FB} alt='Share on Facebook' /></Link>
                <Link to={"https://twitter.com/intent/tweet?text=https://voosh.ai/blogs/mastering-reviews-and-ratings-boost-your-restaurants-reputation-on-doordash-ubereats-and-grubhub/"}>
                <img src={Twitter} alt='Share on Twitter' /></Link>
                <Link to={"https://www.LinkedIn.com/shareArticle?mini=true&url=https://voosh.ai/blogs/mastering-reviews-and-ratings-boost-your-restaurants-reputation-on-doordash-ubereats-and-grubhub/"}>
                <img src={Linkedin} alt='Share on LinkedIn' /></Link>
            </div>
            </div>
            <GetSimilarBlogComponent />
        </div>
      </div>
        
      <Section6 />
    </>
  )
}

export default Blog28122023
