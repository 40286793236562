import React from 'react'
import '../assets/css/about-us.css'
import HeroSection from '../pages/SucessStories/HeroSection'
import Section2 from '../pages/about-us/Section2'
import WhyVoosh from '../pages/about-us/WhyVoosh'
import Section6 from '../pages/homepage/Section6'
import { Helmet } from 'react-helmet'

const AboutUs = () => {

  return (
    <div style={{ backgroundColor: '#F3F3F3' }}>
      <Helmet>
        <title>Voosh: Revolutionizing the Restaurant Industry | Our Story</title>
        <meta name='description' content='Discover the story of Voosh, a leading-edge platform empowering restaurants to maximize potential from 3rd party marketplaces. Learn about our mission, vision, and the journey that led us to revolutionize the restaurant industry' />
        <meta name='keywords' content='Voosh, Restaurant Marketplace Intelligence Platform, third-party marketplaces, restaurant operators, operational efficiency, customer experience, profitability, 3rd Party Marketplace Optimization, Restaurant Technology, Data-Driven Decisions for Restaurants, Restaurant Operations Efficiency, Restaurant Profitability, Voosh Benefits, Restaurant Profit Maximization, Operational Efficiency, Cost Savings, Data-Driven Decisions' />
      </Helmet>
      <HeroSection contents={{ head: 'Welcome to Voosh', s_head: 'The future of restaurant technology. Voosh, a solution in the restaurant industry, was conceived with a vision to empower restaurant operators to unlock their full potential in the realm of 3rd party marketplaces.' }} />
      <Section2 />
      <WhyVoosh />
      <Section6 />
    </div>
  )
}

export default AboutUs
