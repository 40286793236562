import React from 'react'
import '../assets/css/products.css'
import Section6 from '../pages/homepage/Section6'
import Section1 from '../pages/products/Section1'
import Section2 from '../pages/products/Section2'
import Section3DM from '../pages/products-pages/Section3DM'
import { Helmet } from 'react-helmet'

const Productsview = () => {

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Voosh Solutions - Unleash Your Restaurant's Potential with Data-Driven Decisions</title>
        <meta name='description' content="Discover how Voosh's comprehensive suite of solutions empowers restaurant operators to maximize their potential from 3rd party marketplaces" />
        <meta name='keywords' content='Voosh Solutions, Voosh Features, Restaurant Technology, 3rd Party Marketplace Management, Data-Driven Decisions, Restaurant Operations, Restaurant Profitability' />
      </Helmet>
      <Section1 />
      <Section2 />
      <Section3DM />
      <Section6 />
    </div>
  )
}

export default Productsview
