import React from 'react'
import Fin_hero from '../assets/images/Finance-hero.png'
import Fin_icon from '../assets/images/Finance-icon.svg'
import Section6 from '../pages/homepage/Section6'
import Section1v2 from '../pages/products-pages/Section1v2'
import Section2Fin from '../pages/products-pages/Section2Fin'
import { Helmet } from 'react-helmet'

const Finance = () => {

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Reconciliation: Simplifying Your Financial Processes</title>
        <meta name='description' content="Streamline your financial reconciliation and ensure accuracy with Voosh's Reconciliation feature" />
        <meta name='keywords' content='Voosh Solutions, Voosh Features, Restaurant Technology, 3rd Party Marketplace Management, Data-Driven Decisions, Restaurant Operations, Restaurant Profitability' />
      </Helmet>
      <Section1v2 images={{ img1: Fin_icon, img2: Fin_hero }} herotext={{ t1: 'FINANCE AND RECONCILIATION', t2: 'Financial Reconciliation Made Simple', t3: 'Instantly see why your actual payout differs from your estimated payout, reconcile POS data with off-premise orders, and dive into detailed transaction insights to ensure accuracy and boost profitability.' }} />
      <Section2Fin />
      <Section6 />
    </div>
  )
}

export default Finance
