import React from 'react'

const OnpageNav7 = () => {
  return (
    <span className='f-width m-auto blog5'>
    <div className='page-nav'>
        <div className='content_head mb8'>Contents</div>
        <div className='content'>
            <a href='#1'><div><font>01</font> Introduction</div></a>
            <a href='#2'><div><font>02</font> Basics of Promotions on Third-Party Platforms</div></a>
            <a href='#3'><div><font>03</font> Setting Up Promotions that Drive Sales</div></a>
            <a href='#4'><div><font>04</font> Voosh for Efficient Promotions Management</div></a>
            <a href='#5'><div><font>05</font> Tracking and Analyzing Promotions Performance</div></a>
            <a href='#6'><div><font>06</font> Enhancing Brand Reputation and Customer Experience </div></a>
            <a href='#7'><div><font>07</font> Advantage of Voosh's Finance and Reconciliation </div></a>
            <a href='#8'><div><font>08</font> Voosh's Success Stories and Case Studies</div></a>
            <a href='#9'><div><font>09</font> Getting Started with Voosh</div></a>
            <a href='#10'><div><font>10</font> Unlock Your Restaurant's Potential</div></a>
        </div>
    </div>
    </span>
  )
}

export default OnpageNav7