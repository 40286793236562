import React from 'react'
import DC_icon from '../assets/images/DC_icon.svg'
import YThumb_DC from '../assets/images/downtime-controller-voosh-video-thumbnail.webp'
import Section2DC from '../pages/products-pages/Section2DC'
import Section6 from '../pages/homepage/Section6'
import { Helmet } from 'react-helmet'
import Section1v1 from '../pages/products-pages/Section1v1'

const DowntimeController = () => {
  return (
    <div style={{ backgroundColor: '#fff' }} className='pm-h-img'>
      <Helmet>
        <title>Downtime Controller: Eliminate Unexpected Store Closures</title>
        <meta name='description' content="Transform the way you manage your stores across multiple delivery platforms. Voosh offers real-time visibility into your store's operational hours, coupled with the power to directly control it. This tool is designed to significantly reduce downtime and boost your bottom line. Keep your stores always on, always earning." />
        <meta name='keywords' content='DoorDash Downtime, GrubHub Downtime, Uber Eats Downtime, Benefits Of Uptime, Boost Sales, Brand Reputation, Customer Experience, Uptime Management, Drive Sales, Increase Visibility, Store Online, Manage Downtime, Marketing Strategy, Pitfalls To Avoid, Store hours Management, Restaurant Visibility, Online Management, Third-Party Marketplaces, Third-Party Platforms, Store Timing, DoorDash, UberEats, GrubHub' />
      </Helmet>
      <Section1v1 
        icon={DC_icon}
        yt={{ thumbnail: YThumb_DC, vid: '-lD0kLM78Dk', vtitle: 'What Is a Downtime Controller for Restaurants?' }}
        herotext={{ t1: 'DOWNTIME CONTROLLER', t2: 'Eliminate Unexpected Store Closures', t3: "Downtime Controller monitors your store's status across DoorDash, Uber Eats, and more, ensuring you're always open for business when you need to be. With instant alerts, comprehensive analytics, and the ability to turn stores on or off with a click, Downtime Controller is your ultimate tool for minimizing downtime and maximizing revenue." }}
        />
      {/* <Section1v2 images={{ img1: DC_icon, img2: AI_hero }} herotext={{ t1: 'DOWNTIME CONTROLLER', t2: "Eliminate Unexpected Store Closures", t3: "Downtime Controller monitors your store's status across DoorDash, Uber Eats, and more, ensuring you're always open for business when you need to be. With instant alerts, comprehensive analytics, and the ability to turn stores on or off with a click, Downtime Controller is your ultimate tool for minimizing downtime and maximizing revenue." }} /> */}
      <Section2DC />
      <Section6 />
    </div>
  )
}

export default DowntimeController