import React from 'react'
import '../assets/css/sucess-stories.css'
import Section2 from '../pages/SucessStories/Section2'
import { Helmet } from 'react-helmet'

const SucessStory = () => {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Voosh Success Stories: Real Results for Real Restaurants</title>
        <meta name='description' content="Explore our collection of case studies to see how Voosh has helped restaurants like yours maximize their potential and boost profitability" />
        <meta name='keywords' content='Voosh Success Stories, Voosh Case Studies, Restaurant Technology Case Studies, 3rd Party Marketplace Management Case Studies' />
      </Helmet>
      <Section2 />
    </div>
  )
}

export default SucessStory
