import React from 'react'

const OnpageNav4 = () => {
  return (
    <span className='f-width m-auto'>
    <div className='page-nav'>
        <div className='content_head'>Contents</div>
        <div className='content'>
            <a href='#intro'><div><font>01</font> Introduction</div></a>
            <a href='#understanding'><div><font>02</font> Understanding Disputes</div></a>
            <a href='#role'><div><font>03</font> Role of Voosh's Dispute Manager</div></a>
            <a href='#benefits'><div><font>04</font> Benefits </div></a>
            <a href='#reallife'><div><font>05</font> Real-life Success Stories </div></a>
            <a href='#getstarted'><div><font>06</font> How to Get Started </div></a>
            <a href='#unlock'><div><font>07</font> Unlock Dispute Resolution Success</div></a>
        </div>
    </div>
    </span>
  )
}

export default OnpageNav4