import React from 'react'
import '../assets/css/sucess-stories.css'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Whatisvoosh from '../assets/images/whatisvoosh.webp'
import P1 from '../assets/images/whatisvoosh/img89.webp'
import P2 from '../assets/images/whatisvoosh/img90.webp'
import P3 from '../assets/images/whatisvoosh/img91.webp'
import P4 from '../assets/images/whatisvoosh/img92.webp'
import P5 from '../assets/images/whatisvoosh/img93.webp'
import P6 from '../assets/images/whatisvoosh/img94.webp'
import P7 from '../assets/images/whatisvoosh/img95.webp'
import VooshImpact from '../assets/images/whatisvoosh/voosh-impactful-result.webp'
import VooshImpactOnRestaurant from '../assets/images/whatisvoosh/voosh-impact-on-restaurants.webp'
import vooshOnboarding from '../assets/images/whatisvoosh/voosh-onboarding.webp'
import Section3DM from '../pages/products-pages/Section3DM'
import Section6 from '../pages/homepage/Section6'

const WhatIsVoosh = () => {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>What is voosh?</title>
        <meta name='description' content="Voosh values your privacy and is dedicated to safeguarding your personal information. This policy details our approach to data collection, usage, and protection." />
        <meta name='keywords' content='Voosh Privacy Policy,Voosh, Restaurant Marketplace Intelligence Platform, third-party marketplaces, restaurant operators, operational efficiency, customer experience, profitability, 3rd Party Marketplace Optimization, Restaurant Technology, Data-Driven Decisions for Restaurants, Restaurant Operations Efficiency, Restaurant Profitability, Voosh Benefits, Restaurant Profit Maximization, Operational Efficiency, Cost Savings, Data-Driven Decisions' />
      </Helmet>
      {/* <HeroSection contents={{ head: 'Start Getting Money Back from Third-Party Marketplace Disputes Today', s_head: '', bool: 'yes' }} /> */}
      <div className='s1-container'>
        <div className='row f-width m-auto'>
            <div className='col-lg-6 col-sm-12'>
                <div className='s1v1-content'>
                    <h1 className='s1v1-header-wiv'>Start Getting Money Back from Third-Party Marketplace Disputes Today</h1>
                </div>
            </div>
            <div className='col-lg-6 col-sm-12'>
            <div className='whatisvoosh'>
                <img src={Whatisvoosh} alt='what is voosh' />
            </div>
            </div>
        </div>
    </div>
      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h wiv-page'>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>What is Voosh?</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="rtss2">Managing dispute resolution, reconciliation, promotions, and customer reviews on third-party marketplaces is a total pain… unless you have Voosh. That's what we do for you.</p>
        </div>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>What Voosh does:</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="rtss2">Our automated third-party delivery intelligence platform handles disputes for you, saving you significant time each month, and gets money back into your account.</p>
            <p className="rtss2"><Link to={'https://www.linkedin.com/company/&pizza/'}>&pizza</Link>, a dynamic restaurant chain with 55 bustling locations leveraged Voosh's Dispute Manager, <strong>to recover more than $10,000 in disputes in just 10 days</strong>, boasting an impressive 96% dispute Resolution Percentage! </p>
        </div>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>How it works:</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>Our technology accesses your third-party data for analysis, enabling the system to automatically manage disputes, analyze, and provide financial and promotional data, as well as respond to reviews.</p>
            <p className='rtss2'>After a one-time, 30-minute setup, you simply need to open the dashboard to view everything. The process is fully automated, and you can log in anytime to see the data. We also schedule regular meetings with customer success for optimization.</p>
        </div>
        <p className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Voosh Impact at Rock N Roll Sushi:</p>
            </div>
            <div className="t-h-bdr"></div>
            <img className='mt-3' src={VooshImpact} style={{width:"100%"}} alt='Voosh Impact' />
        </p>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Our Solutions:</p>
            </div>
            <div className="t-h-bdr"></div>
            <p> </p>
            <ul>
                <li><strong>Dispute Manager:</strong> Automatically resolves disputes, maximizes earnings, and protects your bottom line.</li>
                <li><strong>Promotions Manager:</strong> AI-driven insights and configurations to skyrocket your marketing ROI.</li>
                <li><strong>Review Manager:</strong> Centralized control for managing and responding to reviews across marketplaces, enhancing your brand reputation.</li>
                <li><strong>Finance and Reconciliation:</strong> Comprehensive financial management with detailed insights into payables, deductions, and more.</li>
                <li><strong>Alerts and Notifications:</strong> Personalized alerts keep you and your team informed about critical metrics and changes.</li>
            </ul>
        </div>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Other examples of real impact with real numbers:</p>
            </div>
            <div className="t-h-bdr"></div>
            <p> </p>
            <ul>
                <li><strong>&Pizza (55 locations):</strong> Over $10,000 recovered in disputes in just 10 days.</li>
                <li><strong>Global Food Brand (300 Locations / 17 Countries):</strong> Projected savings of $400K for 300 stores in 30 days, averaging $1,125 savings per store monthly and an impressive 20x ROI per store.</li>
                <li><strong>New York Brand (30+ locations): </strong> 19.92X ROI, turning an $88K marketing spend into $1.75M in revenue.</li>
            </ul>
        </div>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Voosh Access Network:</p>
            </div>
            <div className="t-h-bdr"></div>
            <div className='row align-items-center v-a-n1'>
                <div className='col-4'>
                    <img src={P1} alt='brand1' />
                </div>
                <div className='col-4'>
                    <img src={P2} alt='brand1' />
                </div>
                <div className='col-4'>
                    <img src={P3} alt='brand1' />
                </div>
            </div>
            <div className='row align-items-center v-a-n2'>
                <div className='col-3'>
                    <img src={P4} alt='brand1' />
                </div>
                <div className='col-3'>
                    <img src={P5} alt='brand1' />
                </div>
                <div className='col-3'>
                    <img src={P7} alt='brand1' />
                </div>
                <div className='col-3'>
                    <img src={P6} alt='brand1' />
                </div>
            </div>
        </div>
        <p className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Voosh's Impact on Restaurants:</p>
            </div>
            <div className="t-h-bdr"></div>
            <img className='vior-img' src={VooshImpactOnRestaurant} alt='Voosh Impact on restaurants' />
        </p>
        <p className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Onboarding in Just 10 Minutes:</p>
            </div>
            <div className="t-h-bdr"></div>
            <img className='mt-3' src={vooshOnboarding} style={{width:"100%"}} alt='Voosh onboarding' />
        </p>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p>Get started today:</p>
            </div>
            <div className="t-h-bdr"></div>
            <p> </p>
            <ul>
                <li>Take advantage of Voosh AI's transformative solutions to earn money back from third-party marketplaces.</li>
                <li>Join the 500+ individual restaurants saving over $200K in disputes.</li>
                <li>Get in Touch: <Link to={'mailto:chris@voosh.ai'}>chris@voosh.ai</Link>, <Link to={'mailto:bilal@voosh.ai'}>bilal@voosh.ai</Link> </li>
            </ul>
        </div>
        <div className='wiv-btn'>
        <a className="btn btn-primary" href="https://meetings.hubspot.com/brandon1142?uuid=cdea5658-3569-49e8-a8c9-21815bea54f8" rel="noreferrer" target="_blank">
            <p className="btn-text d-inline">Book Demo</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                <path d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </a>
        </div>
        <Section3DM />
      </div>
      <Section6 />
    </div>
  )
}

export default WhatIsVoosh
