import React from "react";
import Sec1img from "../../assets/images/S1ROW1AI.png";

const Section2AI = () => {
  return (
    <div className="s2-container-rm">
      <div className="row f-width m-auto align-items-center">
        <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
          <img
            className="img-fluid"
            src={Sec1img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
        <div className="col-lg-5 col-sm-12 review-details-container">
          <div className="s-head-dm">
            <p className="t2">Centralized Analytics Overview</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#16BDCA" }}
          ></div>
          <p className="s2-dm-bdy-text">
          Overwhelmed with managing third-party marketplace data? Let Voosh do the heavy lifting! Get a 360° view of your operations, from sales to reviews, without the manual hassle. Boost your bottom line and discover untapped opportunities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2AI;
