import React from 'react'
import ProductsHero from '../../assets/images/products_hero.webp'
import { logGoogleEvent } from '../../firebase';

const Section1 = () => {
  return (
    <div className='s1-container' >
        <div className='row f-width m-auto'>
            <div className='col-lg-7 col-sm-12 hero-data-p'>
                <p className='hero-primary-text'>Maximize Your Potential with Voosh's Data-Driven Solutions</p>
                <p className='hero-primary-sub-text'>Explore the comprehensive suite of solutions that Voosh offers to help restaurant operators manage 3rd party marketplaces more effectively. From managing reviews to reconciling finances, Voosh provides the tools to make data-driven decisions impacting your bottom line.</p>
                <a href='#Waitinglist' onClick={() => {
                    logGoogleEvent("Product features [Book demo button clicked]", {
                      name: "Product features [Book demo button clicked]",
                    });
                  }}>
                <div className='btn btn-primary btn-with-arrow text-white'><span className='pe-2'>Book Demo </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                        <path d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                </a>
                
            </div>
            <div className='col-lg-5 col-sm-12 product-image-container'>
                <div className='p-hero-img'>
                    <img src={ProductsHero} alt='Voosh Products' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section1