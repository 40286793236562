import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import BtnArrow from '../assets/images/right-arrow.svg'
import { logGoogleEvent } from '../firebase'

const PressCard = ({cardIcon,cardBG,cardText,btnLink}) => {
  return (
    <div className='col-lg-4 col-md-6 col-sm-12'>
        <Card style={{width:"410px", boxShadow: "0px 8px 24px 0px rgba(54, 54, 54, 0.10)"}}>
            <Card.Img style={cardBG} variant="top" src={cardIcon} alt='Voosh in press' />
            <Card.Body>
                <Card.Title>{cardText.t1}</Card.Title>
                <Card.Text>
                {cardText.t2}
                </Card.Text>
                <Link to={btnLink} target='_blank' rel="noopener noreferrer" onClick={() => {
                    logGoogleEvent("Press card Read more button clicked", {
                      name: "Press card Read more button clicked",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                    Read more <img src={BtnArrow} className="ps-2" alt="Right Arrow" />{' '}
                    </div>
                </Link>
            </Card.Body>
        </Card>
    </div>
  )
}

export default PressCard