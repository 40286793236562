import React from 'react'
import SOC2_LOGO from '../../assets/images/aicpa-soc-certification-logo-300x300-1.png'

const Soc2compliant = () => {
  return (
    <div className='soc2-c-section'>
        <div className='d-flex flex-inline f-width m-auto justify-content-center soc-flex'>
            <div className="">
                <img src={SOC2_LOGO} className='img-fluid' alt='Voosh is Now SOC2 Compliant!' />
            </div>
            <div className="align-content-center">
                <p className='p1 fw-bold'><font className="color-brand">Voosh</font> is Now SOC2 Compliant!</p>
                <div className='d-flex d-inline'>
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-shield-lock color-brand"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" /><path d="M12 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12l0 2.5" /></svg>
                    <p className='p2 ps-2'>Your Data Security is Our Top Priority</p>
                </div>
                <div className='d-flex d-inline'>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-scan-eye color-brand"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 8v-2a2 2 0 0 1 2 -2h2" /><path d="M4 16v2a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v2" /><path d="M16 20h2a2 2 0 0 0 2 -2v-2" /><path d="M7 12c3.333 -4.667 6.667 -4.667 10 0" /><path d="M7 12c3.333 4.667 6.667 4.667 10 0" /><path d="M12 12h-.01" /></svg>
                    <p className='p2 ps-2'>Transparency You Can Trust</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Soc2compliant