import { useEffect, useState } from 'react'

export default function useGif(myGif) {
  const [gif, setGif] = useState(myGif)

  useEffect(() => {
    setGif(myGif + '?a=' + 123)
  }, [myGif])

  return gif
}
