import React from 'react'
import AI_hero from '../assets/images/Promotions_hero.png'
import AI_icon from '../assets/images/PM_icon.svg'
import Section2PM from '../pages/products-pages/Section2PM'
import Section6 from '../pages/homepage/Section6'
import { Helmet } from 'react-helmet'
import Section1v2 from '../pages/products-pages/Section1v2'

const PromotionManager = () => {
  return (
    <div style={{ backgroundColor: '#fff' }} className='pm-h-img'>
      <Helmet>
        <title>Promotion Manager: Boost Your Marketing ROI</title>
        <meta name='description' content="Manage and respond to customer reviews across platforms effortlessly with Voosh's Review Manager" />
        <meta name='keywords' content='DoorDash Promotions, GrubHub Promotions, Uber Eats Promotions, Benefits Of Promotions, Boost Sales, Brand Reputation, Customer Experience, Dispute Management, Drive Sales, Effective Promotions, Increase Visibility, Manage Promotions, Marketing Strategy, Pitfalls To Avoid, Promotions Management, Restaurant Promotions, Reviews Management, Third-Party Marketplaces, Third-Party Platforms, Timing Promotions, Types Of Promotions, DoorDash, UberEats, GrubHub' />
      </Helmet>
      <Section1v2 images={{ img1: AI_icon, img2: AI_hero }} herotext={{ t1: 'PROMOTIONS MANAGER', t2: "Streamline Your Restaurant's Marketing Campaigns", t3: 'Set up, manage, and track your restaurant promotions across various third party delivery platforms from a single dashboard. Get detailed insights on sales, ROI, customer engagement, and more. Make informed decisions, tweak campaigns as needed, and ensure every dollar spent is effective.' }} />
      <Section2PM />
      <Section6 />
    </div>
  )
}

export default PromotionManager