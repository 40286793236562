import React from 'react'
import '../assets/css/sucess-stories.css'
import HeroSection from '../pages/SucessStories/HeroSection'
import Section1 from '../pages/user-guide/Section1'

const UserGuide = () => {
  return (
    <div>
        <HeroSection contents={{ head: 'Comprehensive User Guide', s_head: 'Welcome to Voosh - The One-Stop Solution for Restaurant Operators to Manage and Grow Their Business on Third-Party Delivery Platforms. This user guide is designed to provide you with a step-by-step walkthrough of the Voosh dashboard, its features, functionalities, and best practices to enhance your operations and drive business success.'}} />
        <Section1 />
    </div>
  )
}

export default UserGuide