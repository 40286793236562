import React,{useState} from 'react'
import { logGoogleEvent } from '../../firebase'

const Section3DM = () => {
    const [formViewtoggle, setFormView] = useState(false)
    const [formdata, setFormdata] = useState({
        no_of_stores : "", no_of_brands : "", revenue : ""
    })
    const onChange = (e) =>{
        setFormdata({
            ...formdata,
            [e.target.name]:e.target.value
        })
    }
    const onSubmit = (e) =>{
        e.preventDefault()
        setFormView(true)
    }
    const calAgain = () =>{
        setFormView(false)
    }
  return (
    <div className='s2-container-dm'>
        <div className='f-width m-auto align-items-center'>
            <div className='roi-form'>
                <div className={formViewtoggle ? 'd-none' : 'roi-cal'}>
                    <div className='form-head-text text-white text-center'>
                        <p className='t1'>Discover the potential savings with our Dispute Manager</p>
                        <p className='t2'>By addressing order disputes efficiently, see how much you could save every month. Let's get started!</p>
                    </div>
                    <form className='d-flex justify-content-around mt-5 dispute-manager-input-container' onSubmit={onSubmit}>
                        <div className='text-start'>
                            <p>No. of Stores</p>
                            <input className='inputbox' type="number" name='no_of_stores' value={formdata.no_of_stores} onChange={onChange} placeholder='e.g 10' required/>
                        </div>
                        <div className='text-start'>
                            <p>No. of Virtual Brands per Store</p>
                            <input className='inputbox' type="number" name='no_of_brands' value={formdata.no_of_brands} onChange={onChange} placeholder='e.g 3' required/>
                        </div>
                        <div className='text-start'>
                            <p>Monthly Revenue per Virtual Brand</p>
                            <input className='inputbox' type="number" name='revenue' value={formdata.revenue} onChange={onChange}  placeholder='e.g $25,000' required/>
                        </div>
                        <div className='text-start'>
                            <p className='text-white false_text'>&nbsp;</p>
                            <button type='submit' onClick={()=>{logGoogleEvent("ROI calculator data",formdata)}}>Calculate my ROI</button>
                        </div>
                    </form>
                </div>
                <div className={formViewtoggle ? 'roi-cal-result' : 'd-none'}>
                    <div className='form-head-text text-white text-center'>
                        <p className='t1'>With Voosh, you could <font>save</font> approximately <font>{(Math.round(((formdata.no_of_stores*formdata.no_of_brands*formdata.revenue)*(5/100))*(50/100))).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0})}</font> every month! That's <font>{(Math.round(12*(((formdata.no_of_stores*formdata.no_of_brands*formdata.revenue)*(5/100))*(50/100)))).toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 0})}</font> annually!</p>
                        <div className='cal-again-btn mx-auto' onClick={calAgain}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <path d="M13.4463 9.99999H10.5003H13.4463Z" fill="white"/>
                                <path d="M3.16699 2.66666V5.99999H3.55499M3.55499 5.99999C3.99756 4.90541 4.79078 3.9886 5.81034 3.39323C6.82991 2.79787 8.01819 2.5576 9.18897 2.71009C10.3597 2.86257 11.4468 3.39919 12.2799 4.23584C13.113 5.0725 13.6449 6.1619 13.7923 7.33333M3.55499 5.99999H6.50033M13.8337 13.3333V9.99999H13.4463M13.4463 9.99999C13.0031 11.0939 12.2097 12.01 11.1902 12.6049C10.1708 13.1997 8.98282 13.4397 7.81239 13.2872C6.64197 13.1348 5.55512 12.5986 4.72201 11.7625C3.88891 10.9264 3.35656 9.83762 3.20833 8.66666M13.4463 9.99999H10.5003" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className='ps-2'>Calculate again</span>
                        </div>
                        <p className='t2'>Let's talk more about how Voosh can revolutionize your dispute management!</p>
                        <a href='#Waitinglist' onClick={() => {
                    logGoogleEvent("ROI calculator [book demo button clicked]", {
                      name: "ROI calculator [book demo button clicked]",
                    });
                  }}>
                        <div className='btn btn-primary btn-with-arrow text-white'><span className='pe-2'>Book Demo </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                                <path d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section3DM