import React, { useRef } from "react";
import { Accordion } from "react-bootstrap";
import WhyVoosh1 from "../../assets/images/Why1.png";
import WhyVoosh2 from "../../assets/images/Why2.png";
// import WhyVoosh3 from '../../assets/images/Why3.svg'
import WhyVoosh4 from "../../assets/images/Why4.png";

const WhyVoosh = () => {
  const accordianItemRef = useRef(null);

  const handleExpand = (index) => {
    if (!index) {
      return;
    }

    accordianItemRef.current.scrollIntoView({
      behavior: "smooth",
    });
    // accordianItemRef.current.getBoundingClientRect().y += 100;
  };

  return (
    <div className="whyvoosh-container bg-white">
      <div className="row f-width m-auto au-accordion">
        <p className="w-v-text text-center">Why Voosh?</p>
        <div className="col-lg-6 col-sm-12" ref={accordianItemRef}>
          <Accordion defaultActiveKey={"0"} onSelect={(e) => handleExpand(e)}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p className="au-a-h-text mb-0">
                  <font className="text-primary fs-16px pe-2">01</font> Cost
                  Saving
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acc-h-bdr"></div>
                <p>
                  Voosh isn't just a tool—it's a game-changer. By consolidating
                  your third-party marketplace management into one platform, we
                  help you reduce overhead costs and save valuable time. Say
                  goodbye to the days of juggling multiple platforms and hello
                  to streamlined operations.
                </p>
                <div className="acc-img1 animate__animated animate__backInRight">
                  <img src={WhyVoosh1} alt="Voosh cost saving" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <p className="au-a-h-text mb-0">
                  <font className="text-primary fs-16px pe-2">02</font> Profit
                  Maximisation
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acc-h-bdr"></div>
                <p>
                  Maximize your profits with Voosh. Our platform provides
                  actionable insights that help you make data-driven decisions
                  to boost your bottom line. With Voosh, you can optimize your
                  operations and take full advantage of the potential of
                  third-party marketplaces.
                </p>
                <div className="acc-img2 animate__animated animate__backInRight">
                  <img src={WhyVoosh2} alt="Voosh cost saving" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <p className="au-a-h-text mb-0">
                  <font className="text-primary fs-16px pe-2">03</font>{" "}
                  Efficiency
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acc-h-bdr"></div>
                <p>
                  Efficiency is key in the fast-paced restaurant industry. Voosh
                  helps you streamline various processes, from managing
                  promotions to handling disputes, so you can focus on what
                  matters most—serving your customers.
                </p>
                <div className="acc-img3 animate__animated animate__backInRight">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/voosh-47cce.appspot.com/o/Why3.webp?alt=media&token=6cc61056-4230-484c-8e9f-29cc46055734"
                    alt="Voosh cost saving"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <p className="au-a-h-text mb-0">
                  <font className="text-primary fs-16px pe-2">04</font>{" "}
                  Data-Driven Decisions
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acc-h-bdr"></div>
                <p>
                  In the digital age, data is king. Voosh's platform provides
                  you with a clear line of sight across all your channels,
                  enabling you to make informed, data-driven decisions. With
                  Voosh, you're not just reacting to trends but staying ahead of
                  them
                </p>
                <div className="acc-img4 animate__animated animate__backInRight">
                  <img src={WhyVoosh4} alt="Voosh cost saving" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-lg-6 col-sm-12"></div>
      </div>
    </div>
  );
};

export default WhyVoosh;
