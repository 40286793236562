import React from 'react'
import RM_icon from '../assets/images/RM-icon.svg'
import YThumb_RM from '../assets/images/review-manager-thumb.png'
import Section6 from '../pages/homepage/Section6'
import Section1v1 from '../pages/products-pages/Section1v1'
import Section2RM from '../pages/products-pages/Section2RM'
import { Helmet } from 'react-helmet'

const ReviewsRating = () => {

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Review Manager: Amplify Your Restaurant's Reputation</title>
        <meta name='description' content="Manage and respond to customer reviews across platforms effortlessly with Voosh's Review Manager" />
        <meta name='keywords' content='Voosh Solutions, Voosh Features, Restaurant Technology, 3rd Party Marketplace Management, Data-Driven Decisions, Restaurant Operations, Restaurant Profitability' />
      </Helmet>
      <Section1v1 icon={RM_icon} yt={{ thumbnail: YThumb_RM, vid: 'LPG1WT9td0E', vtitle: 'The Future of Restaurant...' }} herotext={{ t1: 'REVIEWS & RATINGS', t2: 'Master Your Reviews with Voosh', t3: 'Centralize, manage, and enhance your reputation effortlessly with Voosh.' }} />
      <Section2RM />
      <Section6 />
    </div>
  )
}

export default ReviewsRating
