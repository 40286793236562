import React from "react";
import '../assets/css/sucess-stories.css'
import '../assets/css/search-form.css'
import Section2 from '../pages/blogs/Section2'
import { Helmet } from 'react-helmet'

const Blogs = () => {
  // const scrollToDivRef = useRef(null);

  // const [searchItem, setSearchItem] = useState('')

  // const handleInputChange = (e) => { 
  //   const searchTerm = e.target.value;
  //   setSearchItem(searchTerm)
  // }
  // useEffect(() => {
  //   // Check if the ref is defined before using scrollIntoView
  //   if (scrollToDivRef.current) {
  //     scrollToDivRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Voosh Blogs</title>
        <meta name='description' content="Explore our collection of case studies to see how Voosh has helped restaurants like yours maximize their potential and boost profitability" />
        <meta name='keywords' content='Voosh Success Stories, Voosh Case Studies, Restaurant Technology Case Studies, 3rd Party Marketplace Management Case Studies' />
      </Helmet>
      <Section2 />
    </div>
  )
}

export default Blogs
