import React from 'react'
import { BlogCards } from '../../components/BlogsImages';

const Section2 = () => {
  return (
    <>
     <BlogCards />
    </>
    
  )
}

export default Section2