import React from 'react'

import Brand1 from '../../assets/images/partners/1.png'
import Brand2 from '../../assets/images/partners/2.png'
import Brand3 from '../../assets/images/partners/3.png'
import Brand4 from '../../assets/images/partners/4.png'
// import Brand5 from '../../assets/images/partners/5.png'
import Brand6 from '../../assets/images/partners/6.png'
import Brand7 from '../../assets/images/partners/7.png'
import Brand8 from '../../assets/images/partners/8.png'
import Brand9 from '../../assets/images/partners/9.png'
import Brand10 from '../../assets/images/partners/10.png'
import Brand11 from '../../assets/images/partners/11.png'
import Brand12 from '../../assets/images/partners/12.png'
import Brand13 from '../../assets/images/partners/13.png'
import Brand14 from '../../assets/images/partners/14.png'
import Brand15 from '../../assets/images/partners/15.png'
import Brand16 from '../../assets/images/partners/16.png'
import Brand17 from '../../assets/images/partners/17.png'
import Brand18 from '../../assets/images/partners/18.png'
import Brand19 from '../../assets/images/partners/19.png'
import Brand20 from '../../assets/images/partners/20.png'
import Brand21 from '../../assets/images/partners/21.png'
import Brand22 from '../../assets/images/partners/22.png'

const Section3 = () => {
  return (
    <div className="s3-container">
      <div className="section2_brands f-width m-auto">
        <p className="s2b_text text-center">OUR PARTNERS</p>
        <div className="logo-slider">
          <div>
            <img src={Brand1} alt="Brand1" />
            <img src={Brand2} alt="Brand2" />
            <img src={Brand3} alt="Brand3" />
            <img src={Brand4} alt="Brand4" />
            {/* <img src={Brand5} alt="Brand5" /> */}
            <img src={Brand6} alt="Brand6" />
            <img src={Brand20} alt="Brand20" />
            <img src={Brand21} alt="Brand21" />
            <img src={Brand22} alt="Brand22" />
            <img src={Brand7} alt="Brand7" />
            <img src={Brand8} alt='Brand8' />
            <img src={Brand9} alt="Brand9" />
            <img src={Brand10} alt="Brand10" />
            <img src={Brand11} alt="Brand11" />
            <img src={Brand12} alt="Brand12" />
            <img src={Brand13} alt="Brand13" />
            <img src={Brand14} alt="Brand14" />
            <img src={Brand15} alt="Brand15" />
            <img src={Brand16} alt="Brand16" />
            <img src={Brand17} alt="Brand17" />
            <img src={Brand18} alt="Brand18" />
            <img src={Brand19} alt="Brand19" />
          </div>
          <div>
          <img src={Brand1} alt="Brand1" />
            <img src={Brand2} alt="Brand2" />
            <img src={Brand3} alt="Brand3" />
            <img src={Brand4} alt="Brand4" />
            {/* <img src={Brand5} alt="Brand5" /> */}
            <img src={Brand6} alt="Brand6" />
            <img src={Brand20} alt="Brand20" />
            <img src={Brand21} alt="Brand21" />
            <img src={Brand22} alt="Brand22" />
            <img src={Brand7} alt="Brand7" />
            <img src={Brand8} alt='Brand8' />
            <img src={Brand9} alt="Brand9" />
            <img src={Brand10} alt="Brand10" />
            <img src={Brand11} alt="Brand11" />
            <img src={Brand12} alt="Brand12" />
            <img src={Brand13} alt="Brand13" />
            <img src={Brand14} alt="Brand14" />
            <img src={Brand15} alt="Brand15" />
            <img src={Brand16} alt="Brand16" />
            <img src={Brand17} alt="Brand17" />
            <img src={Brand18} alt="Brand18" />
            <img src={Brand19} alt="Brand19" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section3
