import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

function CookieConsent() {
    const [show, setShow] = useState('');
    const [read, setRead] = useState(false)
    useEffect(() => {
        if(!localStorage.getItem('c_cnst')){localStorage.setItem('c_cnst','notdone')}
        setShow(localStorage.getItem('c_cnst'))
      },[show])

    const set_consent = () =>{
        localStorage.setItem('c_cnst','done')
        setShow(localStorage.getItem('c_cnst'))
    }
    const read_more = () =>{
        setRead(true)
    }

  if (show === 'notdone') {
    return (
        <div className='c-consent'>
            <Alert variant="light" dismissible>
            <div className='row c-contents'>
                <div className='col-lg-11 col-md-9 col-sm-12'>
                    <p className='mb-0'>We use cookies to enhance your browsing experience, analyze site traffic, and personalize content. By clicking '<strong>Accept</strong>', you consent <strong><font className={!read ? "r_more" : "d-none"} onClick={read_more}>Read more..</font></strong><font className={read ? "moretext" : "d-none"}>to our use of cookies, web beacons, and other similar technologies. For more information on how we use cookies and how you can manage them, please visit our <Link to={'/privacy-policy'}><strong>Privacy Policy</strong></Link>.</font></p>
                </div>
                <div className='col-lg-1 col-md-3 col-sm-12 text-center'>
                    <Button variant='primary' onClick={set_consent}>Accept</Button>
                </div>
            </div>
            </Alert>
        </div>
    );
  }
  return (<></>);
}

export default CookieConsent