const initializeHubspot = () => {
  const element = document.getElementsByTagName("body");
  let body = element[0];
  let script = document.createElement("script");

  script.id = "hs-script-loader";
  script.async = true;
  script.defer = true;
  script.src = "//js.hs-scripts.com/23359971.js";
  script.type = "text/javascript";
  body.appendChild(script);
};

initializeHubspot();
