import React from 'react'
import { Link } from 'react-router-dom'
import Farmburger from '../../assets/images/SS3.png'
import Avtar from '../../assets/images/avtar.webp'
import FarmBurgerIcon from '../../assets/images/t3.webp'
import Section6 from '../homepage/Section6'
import OnpageNav from '../../components/OnpageNav'
import { Helmet } from 'react-helmet'
import { GetSimilarSSComponent } from './SSData'

const SStory2 = () => {

  return (
    <>
      <Helmet>
        <title>Voosh Success Stories: Plnt Burger</title>
        <meta name='description' content="Plnt Burger Thrives in the Online Marketplace Thanks to Voosh" />
        <meta name='keywords' content='Voosh Success Stories, Voosh Case Studies, Restaurant Technology Case Studies, 3rd Party Marketplace Management Case Studies' />
      </Helmet>
      <div className="bg-white mv-overfloX-h">
        <div className="ss-bdr-top"></div>
        <div className="f-width m-auto">
          <div className="d-flex s-nav-flex">
            <Link to={'/'}>
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M14.4793 7.5286L13.1444 6.19525L8.47195 1.52854C8.34678 1.40355 8.17703 1.33334 8.00004 1.33334C7.82305 1.33334 7.6533 1.40355 7.52813 1.52854L2.85572 6.19525L1.52075 7.5286C1.39916 7.65434 1.33188 7.82274 1.3334 7.99754C1.33492 8.17234 1.40512 8.33955 1.52888 8.46316C1.65263 8.58676 1.82005 8.65688 1.99506 8.6584C2.17007 8.65991 2.33868 8.59272 2.46457 8.47128L2.66015 8.27594V13.3333C2.66015 13.687 2.80079 14.0261 3.05115 14.2761C3.30151 14.5262 3.64106 14.6667 3.99512 14.6667H5.99758C6.17461 14.6667 6.34439 14.5964 6.46956 14.4714C6.59474 14.3464 6.66507 14.1768 6.66507 14V11.3333C6.66507 11.1565 6.73539 10.9869 6.86057 10.8619C6.98575 10.7369 7.15553 10.6666 7.33255 10.6666H8.66753C8.84456 10.6666 9.01433 10.7369 9.13951 10.8619C9.26469 10.9869 9.33501 11.1565 9.33501 11.3333V14C9.33501 14.1768 9.40534 14.3464 9.53052 14.4714C9.65569 14.5964 9.82547 14.6667 10.0025 14.6667H12.005C12.359 14.6667 12.6986 14.5262 12.9489 14.2761C13.1993 14.0261 13.3399 13.687 13.3399 13.3333V8.27594L13.5355 8.47128C13.6614 8.59272 13.83 8.65991 14.005 8.6584C14.18 8.65688 14.3474 8.58676 14.4712 8.46316C14.595 8.33955 14.6652 8.17234 14.6667 7.99754C14.6682 7.82274 14.6009 7.65434 14.4793 7.5286Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <Link to={'/success-stories'}>
              <font className="nav-ss-t">Success stories</font>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <font>Plnt Burger Thrives in the Online Marketplace...</font>
          </div>
          <p className="sstory-head">Plnt Burger Thrives in the Online Marketplace Thanks to Voosh</p>
          <div className="row ss-s1">
            <div className="col-lg-6 col-sm-12">
              <img className="v-ss-brand-img" src={Farmburger} alt="Voosh Sucess Story" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="d-flex justify-content-around align-items-center sub-row-ss">
                <img className="avtar-i" src={Avtar} alt="Avtar icon" />
                <p>Written by Chris</p>
                <div className="dot-li"></div>
                <p>25 December 2023</p>
                <div className="dot-li"></div>
                <img className="avtar-i" src={FarmBurgerIcon} alt="Farmburger Icon" />
                <p>Plnt Burger</p>
              </div>
              <div className="ss-card1" id='Introduction'>
                <p>Summary Highlights</p>
                <ul>
                  <li>
                    <font className="fw-bold">-33.13%</font> reduction in error charges within just two weeks.
                  </li>
                  <li>
                    <font className="fw-bold">49.18%</font> dispute win rate.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OnpageNav dnav="d-none" />
      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h'>
        <div className='ss-text-content'>
          <div className="text-row-ss" id='ProblemDescription'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">01</p>
              <p>Introduction</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss">
            Plnt Burger's ethos isn't just a dietary choice but a bold declaration for change. Their offerings celebrate the planet's beauty with handcrafted, plant-based American favorites. By urging patrons to "eat the change you wish to see in the world," Plnt Burger became more than a dining option; it became a movement. Their 14 locations, resonating with inclusivity and versatility, faced the inevitable challenges of the digital marketplace.
            </p>
          </div>
          <div className="text-row-ss" id="SolutionImplementation">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">02</p>
              <p>Problem Description</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss">
              Plnt Burger's popularity has grown in-store and online, and with that, operational efficiency has become critical for success. With an influx of off-premise orders and diverse customer reviews, they have seen an uptick in customer disputes that were directly impacting digital growth.
            </p>
          </div>
          <div className="text-row-ss" id='Results'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">03</p>
              <p>Solution Implementation</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss">
            By partnering with Voosh, Plnt Burger was able to have a clearer line of sight into their overall marketplace program. By using Voosh’s automated dispute manager, Plnt Burger was able to identify service gaps as well as reclaim unclaimed revenue.
            
            </p>
            <ul>
              <li><font className="fw-bold">Operational Efficiency</font>: Reducing inaccurate and unfulfilled orders meant happier customers and heightened brand loyalty.</li>
              <li><font className="fw-bold">Financial Empowerment</font>: Automating disputes provides an easy way for Plnt Burger to provide the ability to recover unclaimed revenue.</li>
              <li><font className="fw-bold">Consolidated Insights</font>: From insights to dispatch, everything about the digital marketplace was now in PlantBurger's command.</li>
            </ul>
          </div>
          <div className="text-row-ss" id='Testimonial'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">04</p>
              <p>Results</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss">PlntBurger's partnership with Voosh yielded results that were tangible and transformative:</p>
            <ul>
              <li>
                <font className="fw-bold">49.18%</font> : dispute win rate
              </li>
              <li>
              Plnt Burger experienced a dispute win rate of <font className="fw-bold">49.18%</font> . The catch? Zero pending disputes, zero manual interventions.
              </li>
              <li>
                The once worrisome Disputes error charges were tamed, plummeting by <font className="fw-bold">33.13%</font> .
              </li>
            </ul>
          </div>
          <div className="ss-card1" id='Conclusion'>
            <p>Client Testimonial</p>
            <p className="t-text-ss">
              "Our mission at PlntBurger goes beyond delicious food. It's about creating a difference, bite by bite. Voosh, in just two weeks, has been able to level up our marketplace strategy by giving us the ability to understand our profits and losses across all channels,  streamline our operations by automating disputes, and bolstering our financial strength. The future looks green in every sense!"
            </p>
            <div className="t-text-ss2">
              <svg className="mx-3" xmlns="http://www.w3.org/2000/svg" width="16" height="2" viewBox="0 0 16 2" fill="none">
                <path d="M1 1H15" stroke="#001F6B" strokeWidth="2" strokeLinecap="round" />
              </svg>
              Chris Treloar, VP of Operations.
            </div>
          </div>
          {/* <div className="text-row-ss" id='Conclusion1'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">05</p>
              <p>Conclusion</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss">
              FarmBurger's commitment to quality, both in its offerings and operations, was further augmented by the insights and controls offered by Voosh. As FarmBurger continues to satiate taste buds and uphold its culinary philosophy, Voosh ensures that the journey remains insightful,
              profitable, and, above all, aligned with FarmBurger's ethos.
            </p>
          </div> */}
          <GetSimilarSSComponent ssid="1" />
        </div>
      </div>
      <Section6 />
    </>
  )
}

export default SStory2
