import React from 'react'
import Sec1img from '../../assets/images/S2RMROW1.png'
import Sec2img from '../../assets/images/S2RMROW2.png'

const Section2RM = () => {
  return (
    <div className='s2-container-rm'>
        <div className='row f-width m-auto align-items-center'>
            <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
                <img className='img-fluid' src={Sec1img} alt='Voosh Dispute Manager feature1' />
            </div>
            <div className='col-lg-5 col-sm-12'>
                <div className='s-head-dm'>
                    <p className='t1' style={{color: "#FF5A1F"}}>01</p>
                    <p className='t2'>Consolidated Review Dashboard</p>
                </div>
                <div className='s2-dm-bdr' style={{backgroundColor:"#FF5A1F"}}></div>
                <p className='s2-dm-bdy-text'>Reviews can make or break your restaurant's image. With Voosh, see all your feedback from DoorDash, UberEats, GrubHub, and more in one place. Prioritize responses and engage customers like never before. Reduce manual efforts, boost satisfaction, and ensure every voice is heard.</p>
            </div>
        </div>
        <div className='row f-width m-auto align-items-center mt-160'>
            <div className='col-lg-5 col-sm-12'>
                <div className='s-head-dm'>
                    <p className='t1' style={{color: "#FF5A1F"}}>02</p>
                    <p className='t2'>Smart AI-Driven Responses</p>
                </div>
                <div className='s2-dm-bdr' style={{backgroundColor:"#FF5A1F"}}></div>
                <p className='s2-dm-bdy-text'>Instantly craft personalized replies with Voosh's AI, ensuring customers feel valued and understood. It prioritizes critical feedback, allowing businesses to address concerns swiftly. The result? Enhanced satisfaction, a stellar reputation, and more time to focus on what truly matters.</p>
            </div>
            <div className='col-lg-7 col-sm-12'>
                <img className='img-fluid' src={Sec2img} alt='Voosh Dispute Manager feature1' />
            </div>
        </div>
    </div>
  )
}

export default Section2RM