import React from 'react'
// import { Link } from 'react-router-dom'
import Section6 from '../homepage/Section6'
// import OnpageNav2 from '../../components/OnpageNav2'
import Img1 from '../../assets/images/userGuide/1.png'
import Img2 from '../../assets/images/userGuide/2.png'
import Img3 from '../../assets/images/userGuide/3.png'
import Img4 from '../../assets/images/userGuide/4.png'
import Img5 from '../../assets/images/userGuide/5.png'
import Img6 from '../../assets/images/userGuide/6.png'
import Img7 from '../../assets/images/userGuide/7.png'
import Img8 from '../../assets/images/userGuide/8.png'
import Img9 from '../../assets/images/userGuide/9.gif'
import Img10 from '../../assets/images/userGuide/10.gif'
import Img11 from '../../assets/images/userGuide/11.png'
import Img12 from '../../assets/images/userGuide/12.png'
import Img13 from '../../assets/images/userGuide/13.gif'
import Img14 from '../../assets/images/userGuide/14.gif'
import Img15 from '../../assets/images/userGuide/15.png'
import Img16 from '../../assets/images/userGuide/16.png'
import Img17 from '../../assets/images/userGuide/17.png'
import Img18 from '../../assets/images/userGuide/18.png'
import Img19 from '../../assets/images/userGuide/19.png'
import Img20 from '../../assets/images/userGuide/20.png'
import Img21 from '../../assets/images/userGuide/21.png'
import Img22 from '../../assets/images/userGuide/22.png'

const Section1 = () => {

  return (
    <>
      <div className='bg-white f-width m-auto pb-4 mv-overfloX-h'>
        <div className='outline-tab'>
        <p className="sstory-head">Summary</p>
        <p className='raw-text-ss ug-sub-txt mt-0'>
          This user guide is designed to provide you with a step-by-step walkthrough of the Voosh dashboard, its features, functionalities, and best practices to enhance your operations and drive business success.
        </p>
        <div className='outline-tab'>
          <p className='t-h-ss m-0'>Outline</p>
          <div className="t-h-bdr"></div>
          <div className='outline-content'>
          <ul className='ul-outline'>
            <li><a href='#Overview'><div><font>01</font> Overview</div></a></li>
            <li><a href='#Sales'><div><font>02</font> Sales & Analysis</div></a></li>
            <li><a href='#Operations'><div><font>03</font> Operations Quality</div></a></li>
            <li><a href='#Reviews'><div><font>04</font> Reviews & Ratings</div></a>
            <ul className='ul-2'>
              <li><a href='#RR_best_practices'><div>Best Practices for Reviews and Ratings</div></a></li>
            </ul>
            </li>
            <li><a href='#Dispute'><div><font>05</font> Dispute Manager</div></a>
            <ul className='ul-2'>
              <li><a href='#DM_best_practices'><div>Best Practices for Dispute Manager</div></a></li>
            </ul>
            </li>
            <li><a href='#Finance'><div><font>06</font> Finance</div></a>
            <ul  className='ul-2'>
              <li><a href='#adrs'><div>Alternative Date Range Selector</div></a></li>
            </ul>
            </li>
            <li><a href='#Promotions'><div><font>07</font> Promotions</div></a></li>
            <li><a href='#Customer'><div><font>08</font> Customer Analysis</div></a></li>
            <li><a href='#Download'><div><font>09</font> Download Reports</div></a></li>
            <li><a href='#UserAccess'><div><font>10</font> User Access</div></a></li>
          </ul>
        </div>
        </div>
        <div className='ss-bdr-top2'></div>
        </div>
      </div>
      <div className="bg-white mv-overfloX-h" id='Overview'>
        <div className="f-width m-auto">
          <p className="sstory-head mt-0">Getting Started</p>
          <p className="raw-text-ss ug-sub-txt mt-0">
            <font>Before diving into the dashboard's functionalities, click <a className='fw-bold' href='https://dashboard.voosh.ai/'>here</a> to navigate to the login page. Enter your user ID and password and click on 'Login' to access the dashboard.</font>
            <font>Upon logging in, you'll arrive at the 'Overview' page—a snapshot of your key metrics. On the left side of the dashboard, you will find a range of modules:</font>
          </p>
        </div>
      </div>
      {/* <OnpageNav2/> */}
      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h'>
        <div className=''>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">01</p>
              <p>Overview Dashboard</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss">
            <font className='fw-bold'>Date & Location Filters</font>: Customize the data displayed using the date range selector, store location, and brand selectors. Choose your channels, be it DoorDash, UberEats, GrubHub, or view them all collectively.
            </p>
            <div className='my-2 text-center'>
                <img className='pb-3 img-fluid' src={Img1} alt='voosh overview'/>
                <img className='img-fluid' src={Img2} alt='voosh overview2'/>
            </div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Key Metrics</font>: The following key performance indicators (KPIs) are displayed:
                <ul className='pt-2'>
                    <li>Total Orders (with city-wise breakdown)</li>
                    <li>Total Sales</li>
                    <li>Average Rating</li>
                    <li>Net Payable</li>
                    <li>Online Rate</li>
                    <li>Visualized Sales & Order Trends: A dynamic graph illustrating your sales and order trajectory. This helps in quickly gauging business performance.</li>
                </ul>
              </li>
              <li id='Sales'>
                <font className="fw-bold">Deductions Breakup</font>: With Voosh's dashboard, FarmBurger delved deep into the details. They could now differentiate whether an issue was due to a wrong order, incorrect quantity, or a missing item, granting them the ability to act promptly and
                decisively.
              </li>
              <li>
                <font className="fw-bold">Feedback Inspection</font>: Within just 15 days, FarmBurger noticed a 6.81% reduction in error charges, a testament to the enhanced clarity and improved processes.
              </li>
            </ul>
          </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">02</p>
              <p>Sales & Analysis</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
            <li>
                <font className="fw-bold">Daily Sales Data</font>: This module presents a daily snapshot. (Note: Financial figures in the “Finance” dashboard are updated weekly by delivery platforms.)
            </li>
              <li>
                <font className="fw-bold">Sales KPIs</font>: Track metrics such as:
                <ul className='pt-2'>
                    <li>Delivered Orders (by channel)</li>
                    <li>Total Sales</li>
                    <li>Canceled Orders</li>
                    <li>Items Sold</li>
                </ul>
              </li>
              <li>
                <font className="fw-bold">Trend Graphs</font>: These visual aids detail out sales and order trends, ensuring you're always in sync with business performance.
              </li>
              <li>
                <font className="fw-bold">Item-Wise Sales Chart</font>: Pinpoint your top and bottom-selling items. Understand growth or degrowth trends to adjust menu offerings accordingly.
              </li>
              <li>
                <font className="fw-bold">Order Cancellation Insights:</font>: Dive into cancellations, understanding reasons and channels responsible.
              </li>
              <li id='Operations'>
                <font className="fw-bold">Revenue Breakup:</font>: Understand which delivery channel is most lucrative, allowing for strategic adjustments.
              </li>
            </ul>
          </div>

          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">03</p>
              <p>Operations Quality</p>
            </div>
            <div className="t-h-bdr"></div>
            <div className='py-3 text-center'>
                <img className='img-fluid' src={Img3} alt='voosh overview'/>
            </div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Operational KPIs</font>: Track issues such as:
                <ul className='pt-2'>
                    <li>Inaccurate Orders</li>
                    <li>Error Charges</li>
                    <li>Unfulfilled Orders</li>
                </ul>
              </li>
              <li>
                <font className="fw-bold">Issue Breakdown</font>: Detailed insights into prevalent issues, helping pinpoint areas for improvement.
              </li>
                <div className='py-3 text-center'>
                    <img className='img-fluid' src={Img4} alt='voosh overview'/>
                </div>
              <li>
                <font className="fw-bold">Operational Trend Graphs</font>: Track trends of inaccurate orders, error charges, and unfulfilled orders to measure operational effectiveness.
              </li>
              <li>
                <font className="fw-bold">Drill-Down Analysis:</font>: Offers a granular view based on locations, brands, orders, or items. Identify performance outliers, enabling strategic decision-making.
              </li>
            </ul>
            <div className='py-3 text-center'>
                <img className='img-fluid' src={Img5} alt='voosh overview'/>
                <span  id='Reviews'></span>
            </div>
          </div>

          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">04</p>
              <p>Reviews and Ratings</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Average Ratings Overview</font>: A consolidated view that showcases the average ratings across various platforms.
              </li>
              <li>
                <font className="fw-bold">Ratings Trend Visualization</font>: Understand how your ratings are performing over time to identify trends and adapt your business strategy.
              </li>
              <li>
                <font className="fw-bold">Unanswered Reviews</font>: Gain insights into reviews that have not been responded to, ensuring no customer feedback is overlooked.
              </li>
              <li>
                <font className="fw-bold">Detailed Review Analysis</font>: By clicking on "See Reviews", users can dive into individual reviews across platforms like UberEats, DoorDash, and GrubHub. Each review is time-sensitive; if not addressed promptly, the opportunity to respond is lost.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img6} alt='voosh overview'/>
              </div>
              <li>
                <font className="fw-bold">Ratings Drill-Down</font>: View ratings based on specific parameters such as location, brand, or individual stores. Identify unanswered reviews, top issues, and compliments specific to each store or brand.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img7} alt='voosh overview'/>
              </div>
              <li>
                <font className="fw-bold">Review Category Drill-Down (AI-Powered)</font>: With the integration of artificial intelligence, this feature classifies compliments and issues, providing insights on key customer sentiments.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img8} alt='voosh overview'/>
              </div>
              <li id='RR_best_practices'>
                <font className="fw-bold">Review Sorting Options</font>: Conveniently sort reviews based on ratings, comments, date, etc., to streamline your analysis.
              </li>
            </ul>
            <p className="raw-text-ss fw-bold">Best Practices for Reviews and Ratings:</p>
            <ul>
                <li>
                    <font className="fw-bold">Zero Unanswered Reviews</font>: Strive to maintain no unanswered reviews. Responding to every customer review fosters trust and potentially mitigates negative perceptions.
                </li>
                <li>
                    <font className="fw-bold">Set Auto Reply with VOOSH AI</font>: Automate responses using VOOSH AI, which offers personalized replies as if a dedicated customer service representative is addressing the feedback. <font className='fw-bold'>To set up</font>, click on "Setup" and follow the easy dashboard instructions. You can also incorporate special offers or discounts in these automated replies for enhanced customer engagement.
                </li>
                <div className='py-3 text-center'>
                  <img className='img-fluid' src={Img9} alt='voosh overview'/>
                </div>
                <p className='raw-text text-center fw-bold'>OR</p>
                <li>
                    <font className="fw-bold">Set Manual Auto Replies</font>: For businesses preferring a more personalized touch without manually addressing each review, preset replies can be created to respond automatically when specific conditions in reviews are met.
                </li>
                <div className='py-3 text-center'>
                  <img className='img-fluid' src={Img10} alt='voosh overview'/>
                  <span id='Dispute'></span>
                </div>
            </ul>
          </div>

          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">05</p>
              <p>Dispute Manager</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Dispute Metrics Overview</font>: A snapshot showing the dispute rate, win rate, lost rate, pending disputes, and more, ensuring businesses have all the critical data at their fingertips.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img11} alt='voosh overview'/>
              </div>
              <li>
                <font className="fw-bold">Dispute Trends Visualization</font>: View graphical representations of dispute trends, win rates, and potential revenue losses.
              </li>
              <li>
                <font className="fw-bold">Dispute Drill-Down</font>: Detailed analysis and data segmentation based on location and brand.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img12} alt='voosh overview'/>
              </div>
              <li id='DM_best_practices'>
                <font className="fw-bold">Disputed Orders Compilation</font>: A comprehensive list of all disputed orders across platforms, highlighting specifics like order location, dispute status, and payouts.
              </li>
            </ul>
            <p className="raw-text-ss fw-bold">Best Practices for Dispute Manager:</p>
            <ul>
                <li>
                    <font className="fw-bold">Set Auto Dispute with Voosh AI</font>: Under “Manage Auto Disputes” option - Enable VOOSH's AI capability to handle disputes automatically, ensuring maximum returns from delivery platforms. This proactive approach not only recovers potential lost revenues but also saves operational time.
                </li>
                <div className='py-3 text-center'>
                  <img className='img-fluid' src={Img13} alt='voosh overview'/>
                </div>
                <p className='raw-text text-center fw-bold'>OR</p>
                <li>
                    <font className="fw-bold">Set Pre-written Auto Disputes</font>: Establish predefined conditions for automated disputes. By configuring criteria once, businesses can ensure that disputes are lodged whenever specific errors or issues arise, without manual intervention.
                </li>
                <div className='py-3 text-center'>
                  <img className='img-fluid' src={Img14} alt='voosh overview'/>
                  <span id='Finance'></span>
                </div>
            </ul>
          </div>


          <div className="text-row-ss" id='adrs'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">06</p>
              <p>Finance</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss"><font className='fw-bold'>Alternative Date Range Selector</font>: Unique to this dashboard, businesses can opt for “weekly time frames” or select based on “payout dates” for precise financial data.</p>
            <div className='py-3 text-center'>
                <img className='img-fluid' src={Img15} alt='voosh overview'/>
              </div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Key Financial Metrics</font>: An overview of order values, platform commissions, merchant deductions, taxes, net payouts, and more.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img16} alt='voosh overview'/>
              </div>
              <li>
                <font className="fw-bold">Trend Graphs</font>: Visualize financial data trends to make informed decisions.
              </li>
              <li>
                <font className="fw-bold">Payables and Deductions Drill-Down:</font>: Analyze payables and deductions across all stores, ensuring full transparency in finances.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img17} alt='voosh overview'/>
                <span  id='Promotions'></span>
              </div>
            </ul>
          </div>


          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">07</p>
              <p>Promotions</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Promotion and Ads Overview</font>: An all-encompassing view of ROI, sales, expenditure on promotions or ads, and listings with lapsed offers.
              </li>
              <li>
                <font className="fw-bold">Promotion Drill-Down</font>: Understand which promotional offers are driving sales and ROI. This section provides details like offer status, associated channels, and activated locations.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img18} alt='voosh overview'/>
              </div>
              <li>
                <font className="fw-bold">Ads Drill-Down:</font>: Detailed insights into ads, ROI, impressions, click rate, conversion rate, ad sales cost, and more, with filters based on location and brand.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img19} alt='voosh overview'/>
                <span id='Customer'></span>
              </div>
            </ul>
          </div>


          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">08</p>
              <p>Customer Analysis</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Customer Metrics Overview</font>: Get insights on total users, new customers, returning customers, and loyal patrons.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img20} alt='voosh overview'/>
              </div>
              <li>
                <font className="fw-bold">Trend Graphs</font>: Monitor trends in customer behaviors and loyalty.
              </li>
              <li id='Download'>
                <font className="fw-bold">Customer Drill-Down:</font>: Deep dive into customer data segmented by location, brand, and channel to better understand customer demographics.
              </li>
            </ul>
          </div>


          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">09</p>
              <p>Download Reports</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Generate Reports</font>: Easily download detailed reports in PDF or Excel formats. Choose specific reports, locations, channels, and time periods for customized data.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img21} alt='voosh overview'/>
              </div>
              <li id='UserAccess'>
                <font className="fw-bold">Schedule Reports</font>: Automate report generation by scheduling them in advance. Define parameters, set frequency, and Voosh will send out reports as specified.
              </li>
            </ul>
          </div>


          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">10</p>
              <p>User Access Control</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="raw-text-ss fw-bold">Features:</p>
            <ul>
              <li>
                <font className="fw-bold">Manage Users</font>: Add new users and specify dashboard access based on user roles or needs. Control which parts of the Voosh platform individual users can access to ensure data security and relevance.
              </li>
              <div className='py-3 text-center'>
                <img className='img-fluid' src={Img22} alt='voosh overview'/>
              </div>
              <p>This comprehensive guide aims to provide users with a clear understanding of how to utilize the Voosh platform's features optimally. With the integration of artificial intelligence and detailed insights, businesses can enhance their operations and customer engagement strategies effectively.</p>
              <li>
                <font className="fw-bold">Tips for a Seamless Experience:</font>
                <ul>
                    <li>Familiarize yourself with the dashboard by exploring all modules.</li>
                    <li>Regularly update your date and location filters to get the most recent insights.</li>
                    <li>Encourage feedback from your team to identify challenges and improve the platform's usability.</li>
                </ul>
              </li>
            </ul>
          </div>


        </div>
      </div>
      <Section6 />
    </>
  )
}

export default Section1
