import React from 'react'

const BlogNav = (props) => {
  return (
    <span className={props.navdata.length > 9 ? 'f-width m-auto blog4' : 'f-width m-auto blog5'}>
    <div className='page-nav'>
        <div className='content_head mb8'>Contents</div>
        <div className='content'>
          {
            props.navdata.map((nav)=>
            
            <a href={'#'+(props.navdata.indexOf(nav)+1)}><div><font>{(props.navdata.indexOf(nav)+1)}</font> {nav} </div></a>
            
            )

          }
        </div>
    </div>
    </span>
  )
}

export default BlogNav