import React from "react";
import Sec1img from "../../assets/images/S2DMROW1.png";
import Sec2img from "../../assets/images/S2DMROW2.png";

const Section2DM = () => {
  return (
    <div className="s2-container-dm">
      <div className="row f-width m-auto align-items-center">
        <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
          <img
            className="img-fluid"
            src={Sec1img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#1C64F2" }}>
              01
            </p>
            <p className="t2">
              Take Control with Voosh's
              <br /> Dispute Manager
            </p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#1C64F2" }}
          ></div>
          <p className="s2-dm-bdy-text">
            Disputes can drain time and profits. With Voosh's Dispute Manager,
            centralize all your issues, automate responses, and recover lost
            revenue faster. Say goodbye to manual tracking and endless
            back-and-forths. Let us handle the disputes while you focus on
            delighting your customers.
          </p>
        </div>
      </div>
      <div className="row f-width m-auto align-items-center mt-160">
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#1C64F2" }}>
              02
            </p>
            <p className="t2">Maximize Resolutions, Minimize Hassles</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#1C64F2" }}
          ></div>
          <p className="s2-dm-bdy-text">
            Unresolved disputes can tarnish your restaurant's reputation and
            hurt revenues. Voosh's Dispute Manager proactively identifies and
            addresses issues, ensuring maximum resolutions with minimum effort.
            Streamline the dispute process, protect your earnings, and focus on
            what you do best - serving delicious meals.
          </p>
        </div>
        <div className="col-lg-7 col-sm-12">
          <img
            className="img-fluid"
            src={Sec2img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
      </div>
    </div>
  );
};

export default Section2DM;
