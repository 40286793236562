// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import ReactGA from 'react-ga4'

const firebaseConfig = {
  // apiKey: "AIzaSyDb5Ne2KH4llvsYoRJvVGQrefmA0bgN1h0",
  // authDomain: "voosh-3.firebaseapp.com",
  // projectId: "voosh-3",
  // storageBucket: "voosh-3.appspot.com",
  // messagingSenderId: "458622032312",
  // appId: "1:458622032312:web:f63ebeb7a23d5cbfe1574d"
  apiKey: "AIzaSyA3NL0NPtzCBXivQD7Ejsbp1kUb3pHiY1c",
  authDomain: "mixing-testnet.firebaseapp.com",
  databaseURL: "https://mixing-testnet-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mixing-testnet",
  storageBucket: "mixing-testnet.appspot.com",
  messagingSenderId: "780953688776",
  appId: "1:780953688776:web:c953768f1955c6601923fd"
}

// Initialize Firebase
// eslint-disable-next-line
const app = initializeApp(firebaseConfig)
// Initialize Google Analytics
ReactGA.initialize("G-E2TTG85P7J", {
  debug: true,
})

export const logGoogleEvent = (eventName, params) => {
  ReactGA.event(eventName, params);
}