import React from 'react'
import { Link } from 'react-router-dom'
import FB from '../assets/images/fb.png'
import Linkedin from '../assets/images/linkedin.png'
import Twitter from '../assets/images/x-twitter.png'

const BlogSharer = (props) => {
  const typeblog = (props.type === "blog" ? "blogs" : "success-stories")
  return (
    <>
       <div className='text-row-ss'>
            <div className='rtss1 pb-3'>Don't forget to share this {props.type}</div>
            <div className='d-flex' style={{gap:'24px'}}>
            <Link to={"https://www.facebook.com/sharer/sharer.php?u=https://voosh.ai/"+typeblog+"/"+props.link +"/"}>
                <img src={FB} alt='Share on Facebook' /></Link>
                <Link to={"https://twitter.com/intent/tweet?text=https://voosh.ai/"+typeblog+"/"+props.link +"/"}>
                <img src={Twitter} alt='Share on Twitter' /></Link>
                <Link to={"https://www.LinkedIn.com/shareArticle?mini=true&url=https://voosh.ai/"+typeblog+"/"+props.link +"/"}>
                <img src={Linkedin} alt='Share on LinkedIn' /></Link>
            </div>
        </div> 
    </>
  )
}

export default BlogSharer