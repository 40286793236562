import React, { useState } from 'react'
import HubspotForm from 'react-hubspot-form'
import Play_btn from '../../assets/images/play_supply.svg'
import Avtar from '../../assets/images/t1.png'
import Youtube_thumb from '../../assets/images/yutube-thumb.webp'
import Avtar1 from '../../assets/images/t4.webp'
import Slider from 'react-slick'
import Avtar3 from '../../assets/images/t3.webp'
import GDimg from '../../assets/images/partners/grouchosdelilogo.png'
import DigLogo from '../../assets/images/partners/dig-logo125x125.png'
import ChopShopLogo from '../../assets/images/partners/chop-shop-logo125x125.png'
import { logGoogleEvent } from '../../firebase'

const Section6 = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'center',
    centerPadding: '85px',
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  }
  const [isActive, setIsActive] = useState(false)
  const [seed, setSeed] = useState(1)
  const reset = () => {
    setSeed(Math.random())
  }
  const handleClick = event => {
    logGoogleEvent("How voosh works video played", {
      name: "How voosh works video played",
    })
    setIsActive(current => !current)
    reset()
  }

  return (
    <div className="s6-container" style={{zIndex:'99'}} id="Waitinglist">
      <div className="f-width m-auto">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="ytubee" key={seed}>
              <div className={isActive ? 'd-none' : 'yt_section'}>
                <img className="yt-thumb" src={Youtube_thumb} alt="youtube_thumbnail" />
                <div className="d-flex thumb-text-btn align-items-end" onClick={handleClick} >
                  <p className="left-bottom mb-1">Understand how voosh works</p>
                  <img src={Play_btn} className="play_btn" alt="play_button" />
                </div>
              </div>
              <iframe
                className={isActive ? 'video_frame' : 'd-none'}
                src={isActive ? 'https://www.youtube.com/embed/hE_3kJdPs8s?autoplay=1' : 'https://www.youtube.com/embed/hE_3kJdPs8s'}
                title="Voosh: All In 1 Restaurant Dashboard for Delivery Financials, Reputation Management, and Operations!"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
            </div>
            <Slider {...settings}>
              <div className="testimonials">
                <img className="t-profile" src={DigLogo} alt="voosh testimonial profile" />
                <p className="t-bite">"Voosh's Dispute Manager has been a game-changer for us. In just 8 months, it has reduced costs and increased profits by automatically disputing order errors. This streamlined tool has greatly improved our third-party delivery efficiency and bottom line. - Sophia Segarra, DIG"</p>
              </div>
              <div className="testimonials">
                <img className="t-profile" src={ChopShopLogo} alt="voosh testimonial profile" />
                <p className="t-bite align-self-center">"Here is a feel good partnership! 🙌 - Original ChopShop"</p>
              </div>
              <div className="testimonials">
                <img className="t-profile" src={GDimg} alt="voosh testimonial profile" />
                <p className="t-bite">"There are not really any "silver bullets" or "magic buttons" in this business, but Voosh is the closest I have seen to one. This product delivers on so many levels - an "easy button," if you will. Thank you, Voosh team; I appreciate you! Onward! 🚀 - 🥪 Deric Rosenbaum, President, Groucho's Deli"</p>
              </div>
              <div className="testimonials">
                <img className="t-profile" src={Avtar1} alt="voosh testimonial profile" />
                <p className="t-bite">“Voosh has been instrumental in resolving disputes for &pizza, efficiently recovering $10,000+ and achieving a 96% win rate in just 10 days!”</p>
              </div>
              <div className="testimonials">
                <img className="t-profile" src={Avtar} alt="voosh testimonial profile" />
                <p className="t-bite">“Within 15 days, Voosh enhanced our operational precision, boosted dispute wins by 69.51%, and fortified our financial health. A game-changer for FarmBurger.”</p>
              </div>
              
              <div className="testimonials">
                <img className="t-profile" src={Avtar3} alt="voosh testimonial profile" />
                <p className="t-bite">“With Voosh, error charges dropped 33.13% in two weeks, and our dispute win rate soared. Operational excellence and customer satisfaction achieved effortlessly!”</p>
              </div>
            </Slider>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 text-center form-section">
            <p className="form-header mb-4">Book Your Live Demo & Begin Your Journey to Revenue Recovery Now</p>
            <HubspotForm portalId="23359971" formId="c7b2ad55-590d-41fa-916a-a359bcb45313" onSubmit={() => {
                    logGoogleEvent("Hubspot Form Submitted", {
                      name: "Hubspot Form Submitted",
                    });
                  }} loading={<div>Loading...</div>} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section6
