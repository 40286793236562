import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/blogs/Picture8.webp'
import Section6 from '../homepage/Section6'
import { Helmet } from 'react-helmet'
import FB from '../../assets/images/fb.png'
import Linkedin from '../../assets/images/linkedin.png'
import Twitter from '../../assets/images/x-twitter.png'
import { GetSimilarBlogComponent } from '../../components/BlogsImages'

const Blog11122023 = () => {

  return (
    <>
      <Helmet>
        <title>Voosh Welcomes EZCater Integration: Making Financial Management a Piece of Cake!</title>
        <meta name='description' content="Voosh.ai improves financial management for restaurants by introducing a new integration with EZCater, providing a comprehensive dashboard that simplifies financial reconciliation and enables in-depth analysis of data from third-party marketplaces." />
        <meta name='keywords' content='finance and reconciliation, DoorDash, Uber Eats, GrubHub, ezCater, finance, financials, reconciliation service, ghost kitchens, multi-unit restaurants, restaurant marketplace intelligence platforms' />
      </Helmet>
      <div className="bg-white mv-overfloX-h">
        <div className="ss-bdr-top"></div>
        <div className="f-width m-auto">
          <div className="d-flex s-nav-flex">
            <Link to={'/'}>
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M14.4793 7.5286L13.1444 6.19525L8.47195 1.52854C8.34678 1.40355 8.17703 1.33334 8.00004 1.33334C7.82305 1.33334 7.6533 1.40355 7.52813 1.52854L2.85572 6.19525L1.52075 7.5286C1.39916 7.65434 1.33188 7.82274 1.3334 7.99754C1.33492 8.17234 1.40512 8.33955 1.52888 8.46316C1.65263 8.58676 1.82005 8.65688 1.99506 8.6584C2.17007 8.65991 2.33868 8.59272 2.46457 8.47128L2.66015 8.27594V13.3333C2.66015 13.687 2.80079 14.0261 3.05115 14.2761C3.30151 14.5262 3.64106 14.6667 3.99512 14.6667H5.99758C6.17461 14.6667 6.34439 14.5964 6.46956 14.4714C6.59474 14.3464 6.66507 14.1768 6.66507 14V11.3333C6.66507 11.1565 6.73539 10.9869 6.86057 10.8619C6.98575 10.7369 7.15553 10.6666 7.33255 10.6666H8.66753C8.84456 10.6666 9.01433 10.7369 9.13951 10.8619C9.26469 10.9869 9.33501 11.1565 9.33501 11.3333V14C9.33501 14.1768 9.40534 14.3464 9.53052 14.4714C9.65569 14.5964 9.82547 14.6667 10.0025 14.6667H12.005C12.359 14.6667 12.6986 14.5262 12.9489 14.2761C13.1993 14.0261 13.3399 13.687 13.3399 13.3333V8.27594L13.5355 8.47128C13.6614 8.59272 13.83 8.65991 14.005 8.6584C14.18 8.65688 14.3474 8.58676 14.4712 8.46316C14.595 8.33955 14.6652 8.17234 14.6667 7.99754C14.6682 7.82274 14.6009 7.65434 14.4793 7.5286Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <Link to={'/blogs'}>
              <font className="nav-ss-t">Blogs</font>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <font>Voosh Welcomes EZCater Integration: Making Financial Management a Piece of Cake!</font>
          </div>
          <p className="sstory-head">Voosh Welcomes EZCater Integration: Making Financial Management a Piece of Cake!</p>
          <div className="row ss-s2">
            <div className="col-lg-6 col-sm-12">
              <img className="v-ss-brand-img rounded" src={Img1} alt="Restaurant Promotions" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="d-flex align-items-center sub-row-ss">
                <p><strong>Posted on</strong> : 12 December 2023</p>
              </div>
              <div className="ss-card1">
                <p>Summary Highlights</p>
                <p className='text-black'>Voosh.ai improves financial management for restaurants by introducing a new integration with EZCater, providing a comprehensive dashboard that simplifies financial reconciliation and enables in-depth analysis of data from third-party marketplaces.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h'>
        <div className=''>
          <div className="text-row-ss">
            {/* <p className='rtss1'>Casual / conversational style for Voosh's upgraded ezCater feature</p>
            <div className="t-h-bdr"></div> */}
            <p className="rtss2">Have you ever found yourself juggling financial data from multiple platforms like a circus act? Well, it's time to drop the pins because Voosh has just made life a whole lot easier for multi-unit restaurant brands. They've added a cherry on top of their already impressive platform with the new EZCater integration. 🍰</p>
          </div>
          <div className="text-row-ss">
            <p className='rtss1 fw-bold'>Voosh.ai's Magic Wand: The EZCater Integration</p>
            <p className='rtss2'>Here's the cool part: Setting up this magic tool takes just 10 minutes. Once done, you get this neat dashboard where you can see all your finances dancing together in harmony. No more playing detective with spreadsheets!</p>
          </div>
          <div className="text-row-ss">
            <p className='rtss1 fw-bold'>Why This Matters for Your Restaurant</p>
            <p className="rtss2"><i>Priyam, the Co-Founder at Voosh.ai, puts it simply: "We're not just adding features; we're revolutionizing how restaurants handle their finances." And he's not just tossing words like salad! This integration means you can spend more time creating amazing food experiences and less time frowning over numbers.</i></p>
            
          </div>
          <div className="text-row-ss">
            <p className='rtss1 fw-bold'>Wrapping It Up With a Bow</p>
            <p className="rtss2">If you're in the restaurant biz and want to make financial management as easy as pie, it's time to check out Voosh.ai's new EZCater integration. Say goodbye to financial juggling and hello to smooth sailing!</p>
            <p  className="rtss2">Craving more info? Dive into the world of <Link to={'/'}>Voosh</Link> and see how they're transforming the restaurant industry, one integration at a time!</p>
          </div>
          <div className='text-row-ss'>
            <div className='rtss1 pb-3'>Don't forget to share this blog</div>
            <div className='d-flex' style={{gap:'24px'}}>
            <Link to={"https://www.facebook.com/sharer/sharer.php?u=https://voosh.ai/blogs/voosh-welcomes-ezcater-integration-making-financial-management-a-piece-of-cake/"}>
                <img src={FB} alt='Share on Facebook' /></Link>
                <Link to={"https://twitter.com/intent/tweet?text=https://voosh.ai/blogs/voosh-welcomes-ezcater-integration-making-financial-management-a-piece-of-cake/"}>
                <img src={Twitter} alt='Share on Twitter' /></Link>
                <Link to={"https://www.LinkedIn.com/shareArticle?mini=true&url=https://voosh.ai/blogs/voosh-welcomes-ezcater-integration-making-financial-management-a-piece-of-cake/"}>
                <img src={Linkedin} alt='Share on LinkedIn' /></Link>
            </div>
        </div>
        <GetSimilarBlogComponent />
        </div>
      </div>
      <Section6 />
    </>
  )
}

export default Blog11122023
