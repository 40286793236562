import React from 'react'

const OnpageNav2 = () => {
  return (
    <span className='f-width m-auto'>
    <div className='page-nav'>
        <div className='content_head'>Contents</div>
        <div className='content'>
            <a href='#intro'><div><font>01</font> Introduction</div></a>
            <a href='#components'><div><font>02</font> Key Components</div></a>
            <a href='#efficient'><div><font>03</font> Efficient Marketplace Management</div></a>
            <a href='#optimizing'><div><font>04</font> Optimizing Your Restaurant's</div></a>
            <a href='#navigating'><div><font>05</font> Navigating Financial Aspects</div></a>
            <a href='#insights'><div><font>06</font> Data-Driven Insights and Analytics</div></a>
            <a href='#success'><div><font>07</font> Success Stories and Case Studies</div></a>
            <a href='#started'><div><font>08</font> Getting Started with Voosh</div></a>
            <a href='#experience'><div><font>09</font> Experience Voosh's Benefits</div></a>
        </div>
    </div>
    </span>
  )
}

export default OnpageNav2