import React from 'react'
import AI_hero from '../assets/images/AI-hero.png'
import AI_icon from '../assets/images/AI-icon.svg'
import Section6 from '../pages/homepage/Section6'
import Section1v2 from '../pages/products-pages/Section1v2'
import Section2AI from '../pages/products-pages/Section2AI'
import { Helmet } from 'react-helmet'

const AnalyticsInsights = () => {

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Analytics & Insights: Harnessing the Power of Data</title>
        <meta name='description' content="Transform raw data into actionable insights with Voosh's robust analytics and reporting features" />
        <meta name='keywords' content='Voosh Solutions, Voosh Features, Restaurant Technology, 3rd Party Marketplace Management, Data-Driven Decisions, Restaurant Operations, Restaurant Profitability' />
      </Helmet>
      <Section1v2 images={{ img1: AI_icon, img2: AI_hero }} herotext={{ t1: 'ANALYTICS & INSIGHTS', t2: 'Empower Your Decisions with Analytics & Insights', t3: 'Easily navigate insights across platforms, transforming data into strategies that drive growth' }} />
      <Section2AI />
      <Section6 />
    </div>
  )
}

export default AnalyticsInsights
