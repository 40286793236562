import React from 'react'
import Sec1img from '../../assets/images/PM1.webp'
import Sec2img from '../../assets/images/PM2.png'

const Section2PM = () => {
  return (
    <div className='s2-container-rm'>
        <div className='row f-width m-auto align-items-center'>
            <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
                <img className='img-fluid' src={Sec1img} alt='Voosh Promotional Manager feature1' />
            </div>
            <div className='col-lg-5 col-sm-12'>
                <div className='s-head-dm'>
                    <p className='t1' style={{color: "#1c64f2"}}>01</p>
                    <p className='t2'>Comprehensive Campaign Configuration</p>
                </div>
                <div className='s2-dm-bdr' style={{backgroundColor:"#1c64f2"}}></div>
                <p className='s2-dm-bdy-text'>Gain the ability to configure every aspect of your campaigns. Set up targeted offers across different platforms and adjust your ads and promotions based on real-time data. Voosh offers unparalleled control at your fingertips, ensuring that your promotional activities are not just tracked but strategically crafted to meet your business objectives.</p>
            </div>
        </div>
        <div className='row f-width m-auto align-items-center mt-160'>
            <div className='col-lg-5 col-sm-12'>
                <div className='s-head-dm'>
                    <p className='t1' style={{color: "#1c64f2"}}>02</p>
                    <p className='t2'>Data-Driven Insights for Smarter Decisions</p>
                </div>
                <div className='s2-dm-bdr' style={{backgroundColor:"#1c64f2"}}></div>
                <p className='s2-dm-bdy-text'>Elevate your promotional strategy with actionable insights derived from comprehensive data analysis. Voosh’s Promotions Manager goes beyond traditional tracking, offering a detailed view into campaign performance, customer engagement, ROI and more. Adjust your strategies on the fly, ensuring that every campaign is optimized for success.</p>
            </div>
            <div className='col-lg-7 col-sm-12'>
                <img className='img-fluid' src={Sec2img} alt='Voosh Promotional Manager feature1' />
            </div>
        </div>
    </div>
  )
}

export default Section2PM