import React from 'react'

const OnpageNav5 = () => {
  return (
    <span className='f-width m-auto'>
    <div className='page-nav'>
        <div className='content_head'>Contents</div>
        <div className='content'>
            <a href='#1'><div><font>01</font> Introduction</div></a>
            <a href='#2'><div><font>02</font> Importance of Financial Reconciliation </div></a>
            <a href='#3'><div><font>03</font> Financial Reconciliation Challenges</div></a>
            <a href='#4'><div><font>04</font> Streamlining Financial Reconciliation</div></a>
            <a href='#5'><div><font>05</font> Integrating Voosh</div></a>
            <a href='#6'><div><font>06</font> Best Practices for Financial Reconciliation</div></a>
            <a href='#7'><div><font>07</font> Unlock Your Restaurant's Potential</div></a>
        </div>
    </div>
    </span>
  )
}

export default OnpageNav5