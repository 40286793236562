import React from 'react'
import FB from '../assets/images/fb.png'
import Linkedin from '../assets/images/linkedin.png'
import Twitter from '../assets/images/x-twitter.png'
import { Link } from 'react-router-dom'

const OnpageNav = (props) => {
  return (
    <span className='f-width m-auto'>
    <div className='page-nav'>
        <div className='content_head'>Contents</div>
        <div className='content'>
            <a href='#Introduction'><div><font>01</font> Introduction</div></a>
            <a href='#ProblemDescription'><div><font>02</font> Problem Description</div></a>
            <a href='#SolutionImplementation'><div><font>03</font> Solution Implementation</div></a>
            <a href='#Results'><div><font>04</font> Results</div></a>
            <a href='#Testimonial'><div><font>05</font> Client Testimonial</div></a>
            <a className={props.dnav} href='#Conclusion'><div><font>06</font> Conclusion</div></a>
        </div>
        <div style={{height:"1px",backgroundColor:"#E9E9E9"}}></div>
        <div className='content-btm'>Don't forget to share this sucess story</div>
        <div className='d-flex' style={{gap:'24px'}}>
          <Link to={"https://www.facebook.com/sharer/sharer.php?u=https://voosh.ai/success-stories"}>
            <img src={FB} alt='Share on Facebook' /></Link>
            <Link to={"https://twitter.com/intent/tweet?text=https://voosh.ai/success-stories/"}>
            <img src={Twitter} alt='Share on Twitter' /></Link>
            <Link to={"https://www.LinkedIn.com/shareArticle?mini=true&url=https://voosh.ai/success-stories/"}>
            <img src={Linkedin} alt='Share on LinkedIn' /></Link>
        </div>
    </div>
    </span>
  )
}

export default OnpageNav