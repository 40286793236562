import React from 'react'

const OnpageNav6 = () => {
  return (
    <span className='f-width m-auto blog4'>
    <div className='page-nav'>
        <div className='content_head mb8'>Contents</div>
        <div className='content'>
            <a href='#1'><div><font>01</font> Introduction</div></a>
            <a href='#2'><div><font>02</font> Right Third-Party Delivery Platforms</div></a>
            <a href='#3'><div><font>03</font> Optimize Your Online Menu</div></a>
            <a href='#4'><div><font>04</font> Streamline Your Workflow</div></a>
            <a href='#5'><div><font>05</font> Focus on Reviews and Ratings</div></a>
            <a href='#6'><div><font>06</font> Manage Finances and Reconciliations</div></a>
            <a href='#7'><div><font>07</font> Promote Your Restaurant on Social Media</div></a>
            <a href='#8'><div><font>08</font> Smooth and Convenient Ordering Process</div></a>
            <a href='#9'><div><font>09</font> Improve Your Packaging and Presentation</div></a>
            <a href='#10'><div><font>10</font> Dispute Management and Chargeback Recovery</div></a>
            <a href='#11'><div><font>11</font> Analyze Data and Insights </div></a>
            <a href='#12'><div><font>12</font> Conclusion</div></a>
            <a href='#13'><div><font>13</font> Unlock Your Restaurant's Potential</div></a>
        </div>
    </div>
    </span>
  )
}

export default OnpageNav6