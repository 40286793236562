import React from 'react'
import '../assets/css/sub-pages.css'
import DM_icon from '../assets/images/DM-icon.svg'
import YThumb_DM from '../assets/images/dispute-manager-thumb.png'
import Section6 from '../pages/homepage/Section6'
import Section1v1 from '../pages/products-pages/Section1v1'
import Section2DM from '../pages/products-pages/Section2DM'
import Section3DM from '../pages/products-pages/Section3DM'
import { Helmet } from 'react-helmet'

const DisputeManager = () => {

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Dispute Manager: Protecting Your Restaurant's Bottom Line</title>
        <meta name='description' content="Seamlessly manage and resolve disputes to protect your restaurant's earnings with Voosh's Dispute Manager" />
        <meta name='keywords' content='Voosh Solutions, Voosh Features, Restaurant Technology, 3rd Party Marketplace Management, Data-Driven Decisions, Restaurant Operations, Restaurant Profitability' />
      </Helmet>
      <Section1v1
        icon={DM_icon}
        yt={{ thumbnail: YThumb_DM, vid: 'Bq_P_IxWdpw', vtitle: 'Lost Profits in Restaurants?🍕...' }}
        herotext={{ t1: 'DISPUTE MANAGER', t2: 'Protecting Your Restaurants Bottom line', t3: 'All your disputes, one dashboard. Stay on top of issues, monitor progress, and see your financial recovery in real-time.' }}
      />
      <Section2DM />
      <Section3DM />
      <Section6 />
    </div>
  )
}

export default DisputeManager
