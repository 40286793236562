import React, { useEffect, useRef } from "react";

const HeroSection = ({contents}) => {
  const scrollToDivRef = useRef(null);
  useEffect(() => {
    // Check if the ref is defined before using scrollIntoView
    if (scrollToDivRef.current) {
      scrollToDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div className="s1-container" ref={scrollToDivRef}>
      <div className="f-width m-auto text-center">
        <div className="row ss-hero-data">
          <h1 className={contents.bool === 'yes' ? "hero-primary-text" : "hero-primary-text ss-h"}>{contents.head}</h1>
          <p className="hero-secondary-text ss-sh">
            {contents.s_head}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
