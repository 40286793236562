import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/blogs/Picture7.webp'
import Section6 from '../homepage/Section6'
import FB from '../../assets/images/fb.png'
import Linkedin from '../../assets/images/linkedin.png'
import Twitter from '../../assets/images/x-twitter.png'
import { Helmet } from 'react-helmet'
import BlogNav from '../../components/BlogNav'
import { GetSimilarBlogComponent } from '../../components/BlogsImages'

const Blog08122023 = () => {

  return (
    <>
      <Helmet>
          <title>Financial Reconciliation for Restaurants: Simplifying Processes and Boosting Profits</title>
          <meta name='description' content="Simplify restaurant financial reconciliation and boost profits with Voosh. Streamline processes, save time, and make data-driven decisions. Discover the power of Voosh's DoorDash, UberEats, Grubhub and EZCater integration now!" />
          <meta name='keywords' content='Boosting Profits, Financial Reconciliation, Restaurants, Simplifying Processes, DoorDash, UberEats, GrubHub, ezCater, Yelp, Google, DoorDash Drive, Finance' />
      </Helmet>
      <div className="bg-white mv-overfloX-h blog4">
        <div className="ss-bdr-top"></div>
        <div className="f-width m-auto">
          <div className="d-flex s-nav-flex">
            <Link to={'/'}>
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M14.4793 7.5286L13.1444 6.19525L8.47195 1.52854C8.34678 1.40355 8.17703 1.33334 8.00004 1.33334C7.82305 1.33334 7.6533 1.40355 7.52813 1.52854L2.85572 6.19525L1.52075 7.5286C1.39916 7.65434 1.33188 7.82274 1.3334 7.99754C1.33492 8.17234 1.40512 8.33955 1.52888 8.46316C1.65263 8.58676 1.82005 8.65688 1.99506 8.6584C2.17007 8.65991 2.33868 8.59272 2.46457 8.47128L2.66015 8.27594V13.3333C2.66015 13.687 2.80079 14.0261 3.05115 14.2761C3.30151 14.5262 3.64106 14.6667 3.99512 14.6667H5.99758C6.17461 14.6667 6.34439 14.5964 6.46956 14.4714C6.59474 14.3464 6.66507 14.1768 6.66507 14V11.3333C6.66507 11.1565 6.73539 10.9869 6.86057 10.8619C6.98575 10.7369 7.15553 10.6666 7.33255 10.6666H8.66753C8.84456 10.6666 9.01433 10.7369 9.13951 10.8619C9.26469 10.9869 9.33501 11.1565 9.33501 11.3333V14C9.33501 14.1768 9.40534 14.3464 9.53052 14.4714C9.65569 14.5964 9.82547 14.6667 10.0025 14.6667H12.005C12.359 14.6667 12.6986 14.5262 12.9489 14.2761C13.1993 14.0261 13.3399 13.687 13.3399 13.3333V8.27594L13.5355 8.47128C13.6614 8.59272 13.83 8.65991 14.005 8.6584C14.18 8.65688 14.3474 8.58676 14.4712 8.46316C14.595 8.33955 14.6652 8.17234 14.6667 7.99754C14.6682 7.82274 14.6009 7.65434 14.4793 7.5286Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </div>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <Link to={'/blogs'}>
              <font className="nav-ss-t">Blogs</font>
            </Link>
            <div className="che-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                  fill="#B3B3B3"
                />
              </svg>
            </div>
            <font>Financial Reconciliation for Restaurants: Simplifying Processes and Boosting Profits</font>
          </div>
          <p className="sstory-head">Financial Reconciliation for Restaurants: Simplifying Processes and Boosting Profits</p>
          <div className="row ss-s2 mb60px">
            <div className="col-lg-6 col-sm-12">
              <img className="v-ss-brand-img rounded" src={Img1} alt="Voosh EZCater Integration" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="d-flex align-items-center sub-row-ss">
                <p><strong>Posted on</strong> : 08 December 2023</p>
              </div>
              <div className="ss-card1">
                <p>Summary Highlights</p>
                <p className='text-black'>
                Simplify restaurant financial reconciliation and boost profits with Voosh. Streamline processes, save time, and make data-driven decisions. Discover the power of Voosh's DoorDash, UberEats, Grubhub and EZCater integration now!
                </p>
              </div>
              <span id='1'></span>
            </div>
          </div>
        </div>
      </div>
      <BlogNav navdata={['Introduction','The Basics of Financial Reconciliation ','The Benefits of Voosh for Financial Reconciliation',"Voosh's EZCater Integration",'Key Features of Voosh','The Versatility of Voosh ','Conclusion',"Discover Voosh's Magic Wand"]} />
      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h'>
        <div className='ss-text-content'>
          <div className="text-row-ss" id='ProblemDescription'>
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">01</p>
              <p>Introduction</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>Financial reconciliation plays a vital role in the restaurant industry, ensuring accurate financial records and helping businesses make informed decisions. However, restaurants often face challenges managing finances, juggling data from multiple platforms, and reconciling discrepancies. Voosh aims to revolutionize financial management for restaurants by simplifying processes and boosting profits through their innovative platform and the new EZCater, DoorDash, UberEats and Grubhub integration.</p>
            <p className='rtss1'>Importance of financial reconciliation in the restaurant industry</p>
            <p className='rtss2'>Accurate financial reconciliation is essential for restaurants to maintain their financial health, identify areas for improvement, and make data-driven decisions. It also helps businesses comply with regulations and avoid costly errors.</p>
            <p className='rtss1'>Challenges faced by restaurants in managing finances</p>
            <p className='rtss2'>Restaurants often struggle with managing finances across various platforms, resulting in time-consuming tasks, potential errors, and difficulty identifying discrepancies. This can lead to decreased efficiency and lost profits.</p>
            <p className='rtss1'>The role of Voosh in simplifying financial processes and boosting profits</p>
            <span id='2'></span>
            <p className='rtss2'>Voosh addresses the financial management challenges faced by restaurants by offering a comprehensive platform with seamless integrations, including the new EZCater, DoorDash, UberEats and Grubhub integration. This innovative solution helps restaurants save time, reduce errors, and make better financial decisions, ultimately leading to increased profits.</p>
           </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">02</p>
              <p>The Basics of Financial Reconciliation for Restaurants</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>TFinancial reconciliation is an essential process for restaurants to maintain accurate financial records and make informed decisions. It involves comparing and matching financial data from different sources, ensuring that transactions are recorded correctly and identifying any discrepancies.</p>
            <p className='rtss1'>Definition and Purpose of Financial Reconciliation</p>
            <p className='rtss2'>Financial reconciliation is the process of ensuring that financial records are accurate and consistent across multiple sources. For restaurants, this means comparing data from point-of-sale (POS) systems, bank statements, and third-party delivery platforms. The main purpose of financial reconciliation is to identify and resolve discrepancies, reduce errors, and maintain an accurate financial picture for the business.</p>
            <p className='rtss1'>Common Financial Reconciliation Tasks in Restaurants</p>
            <p className='rtss2'>Some common financial reconciliation tasks in restaurants include:</p>
            <ul>
                <li>Comparing daily sales reports from the POS system with bank deposits</li>
                <li>Reconciling third-party delivery platform payouts with sales records</li>
                <li>Verifying cash deposits and credit card transactions</li>
                <li>Checking inventory levels and adjusting for variances</li>
            </ul>
            <p className='rtss2'>As the restaurant industry evolves and adopts new technology, financial reconciliation tasks may become more complex. Voosh is revolutionizing how restaurants handle their finances with the new EZCater, DoorDash, UberEats and Grubhub integration, making financial management a piece of cake. Setting up this integration takes just 10 minutes, after which restaurants can enjoy a unified financial dashboard, eliminating the need to play detective with spreadsheets.</p>
            <p className='rtss1'>The Impact of Accurate Financial Reconciliation on Restaurant Profits</p>
            <p className='rtss2'>Accurate financial reconciliation is crucial for restaurants to maintain financial health, identify areas for improvement, and make data-driven decisions. By identifying and resolving discrepancies, restaurants can ensure that they are not losing money due to errors or mismanagement. Moreover, accurate financial records enable restaurants to monitor their performance, adjust strategies, and ultimately boost profits.</p>
            <span id='3'></span>
            <p className='rtss2'>Voosh's EZCater integration is not just an added feature, but a significant step towards revolutionizing restaurant financial management. By adopting this integration, restaurants can focus more on creating amazing food experiences and less on frowning over numbers, ultimately leading to increased profits and smooth financial management.</p>
          </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">03</p>
              <p>The Benefits of Voosh for Financial Reconciliation</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>Voosh offers numerous advantages for restaurants looking to simplify their financial reconciliation processes. These benefits not only make financial management more efficient but also contribute to increased profits for the business.</p>
            <p className='rtss1'>Streamlined Financial Management Through a Single Platform</p>
            <p className='rtss2'>One significant benefit of Voosh is its ability to streamline financial management by consolidating data from multiple sources onto a single platform. As mentioned in the additional text, the new EZCater integration, which takes just 10 minutes to set up, provides a unified financial dashboard that eliminates the need for manual reconciliation using spreadsheets. This efficient approach saves time and reduces the risk of errors in financial records.</p>
            <p className='rtss1'>Integration with Third-Party Delivery Platforms like DoorDash, Uber Eats, and GrubHub</p>
            <p className='rtss2'>Voosh seamlessly integrates with popular third-party delivery platforms such as DoorDash, Uber Eats, and GrubHub. This integration enables restaurants to easily manage their finances across multiple platforms, ensuring that all financial data is accurate and consistent. As a result, restaurants can focus on creating amazing food experiences rather than spending time and effort juggling financial data.</p>
            <p className='rtss1'>Automated Chargeback Recovery to Save Money and Boost Profits</p>
            <p className='rtss2'>Another notable feature of Voosh is its automated chargeback recovery system. This system helps restaurants automatically recover lost profits due to disputes on third-party marketplaces. By efficiently addressing these chargebacks, restaurants can save money and boost their overall profits.</p>
            <p className='rtss1'>Real-Time Delivery Data and Data-Driven Insights for Better Decision-Making</p>
            <p className='rtss2'>Voosh provides restaurants with real-time delivery data and data-driven insights, enabling them to make better, more informed decisions. By understanding their financial performance in real-time, restaurants can quickly identify and address issues, ultimately leading to improved financial management and increased profits.</p>
            <span id='4'></span>
            <p className='rtss2'>In conclusion, Voosh's comprehensive platform, including the new EZCater integration, revolutionizes restaurant financial reconciliation by simplifying processes and boosting profits. Restaurants interested in optimizing their financial management should consider exploring Voosh's features and benefits to experience smoother financial management and increased profitability.</p>
          </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">04</p>
              <p>Voosh's EZCater Integration: Making Financial Management a Piece of Cake</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className="rtss2">Voosh has recently introduced the EZCater integration, a game-changer for multi-unit restaurant brands looking to simplify their financial management. This innovative feature is designed to save time and effort, making financial reconciliation easier and more efficient for busy restaurateurs.</p>
            <p className="rtss1">Overview of the New EZCater Integration</p>
            <p className="rtss2">The EZCater integration is a new feature on Voosh's already impressive platform, designed to revolutionize how restaurants handle their finances. As Priyam, the Co-Founder at Voosh.ai, puts it: "We're not just adding features; we're revolutionizing how restaurants handle their finances." This integration allows restaurants to focus more on creating amazing food experiences and less on managing their financial data.</p>
            <p className="rtss1">The 10-Minute Setup Process</p>
            <p className="rtss2">Setting up the EZCater, DoorDash, UberEats and Grubhub integration is a breeze, taking just 10 minutes to complete. This quick and easy process is like a quick marinade for your financial data, allowing you to see all your financials dancing together in harmony on a neat dashboard. No more playing detective with spreadsheets and juggling multiple platforms!</p>
            <p className="rtss1">The Benefits of a Unified Financial Dashboard for Multi-Unit Restaurant Brands</p>
            <p className="rtss2">With Voosh's integrations, multi-unit restaurant brands can enjoy the benefits of a unified financial dashboard. This dashboard provides an organized, efficient, and user-friendly way to manage finances across multiple locations and platforms. The integration also eliminates the need for manual reconciliation, saving time and reducing the risk of errors.</p>
            <span id='5'></span>
            <p className="rtss2">In conclusion, Voosh's Finance Manager is a game-changer for restaurants looking to simplify their financial management processes. If you're in the restaurant business and want to make financial management as easy as pie, it's time to check out <Link to={'/'}>Voosh.ai</Link>. Say goodbye to financial juggling and hello to smooth sailing!</p>
          </div>
          <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">05</p>
              <p>Key Features of Voosh for Simplifying Financial Processes</p>
            </div>
            <div className="t-h-bdr"></div>
           <p className='rtss2'>Voosh offers an array of innovative features designed to streamline and simplify financial processes for restaurants, making it easier than ever for businesses to manage their finances and boost profits.</p>
           <p className='rtss1'>Dispute Management</p>
           <p className='rtss2'>Voosh's dispute management feature allows restaurants to efficiently handle chargebacks and disputes arising from third-party delivery platforms, ensuring that businesses can recover lost profits and maintain accurate financial records. By automating this process, Voosh saves restaurants time and effort, allowing them to focus on providing exceptional food experiences for their customers.</p>
           <p className='rtss1'>Finance and Reconciliation</p>
           <p className='rtss2'>Voosh's finance and reconciliation feature consolidates financial data from multiple sources, such as POS systems, bank statements, and third-party delivery platforms, onto a single platform. This unified dashboard eliminates the need for manual reconciliation, reducing the risk of errors and saving restaurants time. With Voosh, financial management becomes as easy as pie.</p>
           <p className='rtss1'>Promotions Management</p>
           <p className='rtss2'>Voosh's promotions management feature allows restaurants to manage and track promotions across all their delivery platforms from one central location. By streamlining this process, Voosh helps restaurants optimize their promotional strategies, attract more customers, and ultimately increase profits.</p>
           <p className='rtss1'>Review and Rating Management</p>
           <p className='rtss2'>In addition to simplifying financial processes, Voosh also helps restaurants enhance their brand reputation and customer experience through its review and rating management feature. This feature enables businesses to monitor and respond to customer feedback across multiple platforms, ensuring a consistent brand image and driving customer satisfaction.</p>
          <span id='6'></span>
          <p className='rtss2'>In conclusion, Voosh's innovative features revolutionize how restaurants handle their finances, enabling businesses to focus more on creating amazing food experiences and less on frowning over numbers. If you're in the restaurant biz and want to make financial management a piece of cake, it's time to check out <Link to={'/'}>Voosh.ai</Link>.</p>
        </div>
        <div className="text-row-ss">
            <div className="t-h-ss align-items-center">
              <p className="t-s pe-2">06</p>
              <p>The Versatility of Voosh for Restaurants of All Sizes</p>
            </div>
            <div className="t-h-bdr"></div>
            <p className='rtss2'>Voosh's innovative platform, caters to restaurants of all sizes, from ghost kitchens to multi-unit restaurants. By offering a comprehensive suite of features and tools, Voosh ensures that businesses of any size can simplify their financial processes and boost profits.</p>
            <p className='rtss1'>Ghost Kitchens</p>
            <p className='rtss2'>Ghost kitchens, which operate solely for delivery and carryout, face unique financial management challenges. With Voosh's platform, ghost kitchens can efficiently manage their finances across multiple third-party delivery platforms, streamlining their processes and saving valuable time. As a result, ghost kitchen owners can focus more on creating amazing food experiences for their customers, knowing that their financial management is in good hands.</p>
            <p className='rtss1'>Multi-Unit Restaurants</p>
            <p className='rtss2'>Multi-unit restaurants often struggle with juggling financial data from multiple locations and platforms. Voosh's platform, including the 10-minute setup, provides a unified financial dashboard that consolidates data from various sources. This allows multi-unit restaurants to easily manage their finances across all their locations, ensuring accurate and consistent financial records. The efficient financial management offered by Voosh enables multi-unit restaurant brands to focus on delivering exceptional food and service to their customers.</p>
            <p className='rtss1'>Unique Selling Points of Voosh for Efficient Financial Management</p>
            <p className='rtss2'>Voosh offers several unique selling points that make it an invaluable partner for restaurants of all sizes looking to improve their financial management. The platform's seamless integration with third-party delivery platforms, automated chargeback recovery system, and real-time data all contribute to its effectiveness in simplifying financial processes and boosting profits. Moreover, Voosh's platform is designed to be versatile and adaptable, catering to the unique needs of different types of restaurants.</p>
            <span id='7'></span>
            <p className='rtss2'>In conclusion, Voosh's innovative features make financial management a piece of cake for restaurants of all sizes. If you're in the restaurant business and want to say goodbye to financial juggling and hello to smooth sailing, it's time to check out Voosh.ai and explore their platform to see how they're transforming the restaurant industry!</p>
        </div>
        <div className="text-row-ss">
          <div className="t-h-ss align-items-center">
            <p className="t-s pe-2">07</p>
            <p>Conclusion</p>
          </div>
          <div className="t-h-bdr"></div>
          <p className='rtss2'>In conclusion, Voosh's innovative platform, offers a comprehensive solution for restaurants looking to simplify their financial reconciliation processes. This powerful tool provides a wide range of benefits, from streamlining financial management through a single platform to offering seamless integration with third-party delivery platforms like DoorDash, Uber Eats, ezCater and GrubHub. As Priyam, the Co-Founder at Voosh.ai, puts it: "We're not just adding features; we're revolutionizing how restaurants handle their finances."</p>
          <p className='rtss2'>By simplifying financial processes, Voosh enables restaurants to focus more on creating amazing food experiences for their customers and less on managing their financial data. In turn, this focus on efficient financial management can lead to increased profits, making Voosh an invaluable partner for restaurants of all sizes. With a quick 10-minute setup, Voosh offers a neat dashboard where all financials can dance together in harmony, eliminating the need for playing detective with spreadsheets.</p>
          <span id='8'></span>
          <p className='rtss2'>If you're in the restaurant business and want to make financial management as easy as pie, it's time to explore Voosh's 30-day free trial and product features. Say goodbye to financial juggling and hello to smooth sailing! For more information, dive into the world of Voosh and see how they're transforming the restaurant industry.</p>
        </div>

        <div className="text-row-ss">
          <div className="t-h-ss align-items-center">
            <p className="t-s pe-2">08</p>
            <p>Discover Voosh's Magic Wand</p>
          </div>
          <div className="t-h-bdr"></div>
          <p className='rtss2'>If you're in the restaurant business and want to make financial management as easy as pie, it's time to explore Voosh.ai. With just a 10-minute setup, you can enjoy a unified financial dashboard that eliminates the need for manual reconciliation and juggling financial data from multiple platforms.</p>
          <ul>
            <li>Get started by visiting <Link to={'/'}>https://voosh.ai</Link> to learn more about Voosh's platform and how they're revolutionizing restaurant financial management.</li>
            <li>Dive deeper into Voosh's finance features and the new EZCater integration by visiting <Link to={'/finance'}>https://voosh.ai/finance</Link> .</li>
            <li>Discover Voosh's Product Features, From dispute management to promotions management, explore all the innovative features Voosh offers by visiting <Link to={'/product-features'}>https://voosh.ai/product-features</Link>. </li>
            <li>Unlock valuable data-driven insights and real-time delivery data to make better decisions for your restaurant by visiting <Link to={'/analytics-insights'}>https://voosh.ai/analytics-insights</Link> .</li>
            <li>Enhance your restaurant's brand reputation and customer experience by managing reviews and ratings across third-party marketplaces. Learn more by visiting <Link to={'/review-rating'}>https://voosh.ai/review-rating</Link> .</li>
          </ul>
          <p className='rtss2'>Don't miss the opportunity to revolutionize your restaurant's financial management with Voosh.ai. Say goodbye to financial juggling and hello to smooth sailing!</p>
        </div>
         
            <div className='text-row-ss'>
              <div className='rtss1 pb-3'>Don't forget to share this blog</div>
              <div className='d-flex' style={{gap:'24px'}}>
              <Link to={"https://www.facebook.com/sharer/sharer.php?u=https://voosh.ai/blogs/financial-reconciliation-for-restaurants-simplifying-processes-and-boosting-profits/"}>
                  <img src={FB} alt='Share on Facebook' /></Link>
                  <Link to={"https://twitter.com/intent/tweet?text=https://voosh.ai/blogs/financial-reconciliation-for-restaurants-simplifying-processes-and-boosting-profits/"}>
                  <img src={Twitter} alt='Share on Twitter' /></Link>
                  <Link to={"https://www.LinkedIn.com/shareArticle?mini=true&url=https://voosh.ai/blogs/financial-reconciliation-for-restaurants-simplifying-processes-and-boosting-profits/"}>
                  <img src={Linkedin} alt='Share on LinkedIn' /></Link>
              </div>
            </div>
            <GetSimilarBlogComponent />
        </div>
      </div>
        
      <Section6 />
    </>
  )
}

export default Blog08122023
