import React from 'react'
import HeroSection from '../pages/SucessStories/HeroSection'
import Section6 from '../pages/homepage/Section6'
import PressCard from '../components/PressCard'
import P_logo1 from '../assets/images/press/1.png'
import P_logo2 from '../assets/images/press/2.png'
import P_logo3 from '../assets/images/press/3.png'
import P_logo4 from '../assets/images/press/4.png'
import P_logo5 from '../assets/images/press/5.png'
import P_logo6 from '../assets/images/press/6.png'
import P_logo7 from '../assets/images/press/7.png'
import P_logo8 from '../assets/images/press/8.png'
import P_logo9 from '../assets/images/press/9.png'
import P_logo10 from '../assets/images/press/10.png'
import P_logo11 from '../assets/images/press/11.png'
import P_logo12 from '../assets/images/press/12.png'
import P_logo13 from '../assets/images/press/13.png'
import P_logo14 from '../assets/images/press/14.png'
import P_logo15 from '../assets/images/press/15.png'
import P_logo16 from '../assets/images/press/16.png'
import P_logo17 from '../assets/images/press/17.png'
import P_logo18 from '../assets/images/press/18.png'
import P_logo19 from '../assets/images/press/19.png'
import P_logo20 from '../assets/images/press/20.png'
import P_logo21 from '../assets/images/press/21.png'

import { Helmet } from 'react-helmet'

const Press = () => {
  return (
    <div className='bg-white'>
      <Helmet>
        <title>Voosh : In The Press</title>
        <meta name='description' content="Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection" />
        <meta name='keywords' content='Voosh Solutions, Voosh In Press, Restaurant Technology, 3rd Party Marketplace Management, Data-Driven Decisions, Restaurant Operations, Restaurant Profitability, Voosh Launched, Voosh in media, Voosh in news' />
      </Helmet>
        <HeroSection contents={{ head: 'In The Press', s_head: '' }} />
        <div className='press-slider-container mt-5'>
            <div className='row f-width m-auto press-cards'>
              <PressCard cardIcon={P_logo20} cardText={{t1:"Voosh.ai Gears Up to Drive Growth and Expansion of Its Unified Data Platform for Restaurants", t2:"26 October 2023 • Restaurant Technology News"}} btnLink={"https://restauranttechnologynews.com/2023/10/voosh-ai-gears-up-to-drive-growth-and-expansion-of-its-unified-data-platform-for-restaurants/"} />
              <PressCard cardIcon={P_logo2} cardText={{t1:"Voosh Offers Free 30-Day Trial of Its Revolutionary Restaurant Intelligence Platform", t2:"25 October 2023 • EZ Newswire"}} btnLink={"https://news.eznewswire.com/news/voosh-offers-free-30-day-trial-of-its-revolutionary-restaurant-intelligence-platform"} />
              <PressCard cardIcon={P_logo1} cardText={{t1:"Voosh Offers Free 30-Day Trial of Its Revolutionary Restaurant Intelligence Platform", t2:"25 October 2023 • WRAL.com"}} btnLink={"https://www.wral.com/story/voosh-offers-free-30-day-trial-of-its-revolutionary-restaurant-intelligence-platform/21114701/"} />

              <PressCard cardIcon={P_logo2} cardText={{t1:"Voosh Welcomes Hospitality Tech Veteran Chris Munz as Vice President of Growth", t2:"20 October 2023 • EZ Newswire"}} btnLink={"https://app.eznewswire.com/news/voosh-welcomes-hospitality-tech-veteran-chris-munz-as-vice-president-of-growth"} />
              <PressCard cardIcon={P_logo21} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"10 October 2023 • Big Technology"}} btnLink={"https://www.bigtechnology.com/p/vooshs-dispute-manager-elevates-restaurant"} />
              <PressCard cardIcon={P_logo20} cardText={{t1:"PLNT Burger Sees Marked Improvements in Dispute Rates and Returned Revenue with Voosh ...", t2:"04 October 2023 • Restaurant Technology News"}} btnLink={"https://restauranttechnologynews.com/2023/10/plnt-burger-sees-marked-improvements-in-dispute-rates-and-returned-revenue-with-voosh-restaurant-analytics-platform/"} />
              <PressCard cardIcon={P_logo19} cardText={{t1:"Leveraging Viral Moments with Priyam Saraswat of Voosh.ai - Cali BBQ Media - Digital Content & Media", t2:"02 October 2023 • Cali BBQ Media"}} btnLink={"https://content.calibbq.media/leveraging-viral-moments-with-priyam-saraswat-of-voosh-ai/"} />
              <PressCard cardIcon={P_logo8} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"02 October 2023 • Bluefield Daily Telegraph"}} btnLink={"https://www.bdtonline.com/region/voosh-s-dispute-manager-elevates-restaurant-operations-for-first-u-s-customer-plnt-burger/article_a4d5475d-490a-5117-9160-0f8d83a3877e.html"} />
              <PressCard cardIcon={P_logo6} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"02 October 2023 • Peru Tribune"}} btnLink={"https://www.perutribune.com/news/regional/voosh-s-dispute-manager-elevates-restaurant-operations-for-first-u-s-customer-plnt-burger/article_b233dcc9-1e74-57f0-aa72-7917a25ca653.html"} />
              <PressCard cardIcon={P_logo1} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"02 October 2023 • WRAL.com"}} btnLink={"https://www.wral.com/story/voosh-s-dispute-manager-elevates-restaurant-operations-for-first-u-s-customer-plnt-burger/21078209/"} />
              <PressCard cardIcon={P_logo18} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"02 October 2023 • Chronicle-Tribune"}} btnLink={"https://www.chronicle-tribune.com/news/wire/voosh-s-dispute-manager-elevates-restaurant-operations-for-first-u-s-customer-plnt-burger/article_49e39dc3-1374-500f-81f1-453532ee2283.html"} />
              <PressCard cardIcon={P_logo4} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"02 October 2023 • AP News"}} btnLink={"https://apnews.com/press-release/ez-newswire/hospitality-and-leisure-industry-58a851bdc90bccb5889d3284ddbc2867"} />
              <PressCard cardIcon={P_logo2} cardText={{t1:"Voosh's Dispute Manager Elevates Restaurant Operations for First U.S. Customer, PLNT Burger", t2:"02 October 2023 • EZ Newswire"}} btnLink={"https://app.eznewswire.com/news/voosh-s-dispute-manager-elevates-restaurant-operations-for-first-u-s-customer-plnt-burger"} />


              <PressCard cardIcon={P_logo17} cardText={{t1:"The Crucial Role of Dispute Management and Data Consolidation for Restaurants | QSR magazine", t2:"27 September 2023 • QSR magazine"}} btnLink={"https://www.qsrmagazine.com/outside-insights/crucial-role-dispute-management-and-data-consolidation-restaurants"} />
              <PressCard cardIcon={P_logo14} cardText={{t1:"Tech Tracker: Is social video the future of restaurant reviews? | Voosh now uses AI to respond to customer disputes", t2:"19 September 2023 • Nation's Restaurant News"}} btnLink={"https://www.nrn.com/technology/tech-tracker-social-video-future-restaurant-reviews"} />
              <PressCard cardIcon={P_logo16} cardText={{t1:"Tech Tracker: Is social video the future of restaurant reviews? | Voosh now uses AI to respond to customer disputes", t2:"19 September 2023 • Restaurant Hospitality"}} btnLink={"https://www.restaurant-hospitality.com/technology/tech-tracker-social-video-future-restaurant-reviews"} />
              <PressCard cardIcon={P_logo15} cardText={{t1:"News Briefs Announcement | Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"05 September 2023 • MultiBriefs.com"}} btnLink={"https://www.multibriefs.com/briefs/FBM/FBM090523.php"} />
              <PressCard cardIcon={P_logo1} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • WRAL.com"}} btnLink={"https://www.wral.com/story/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/21023902/"} />
              <PressCard cardIcon={P_logo2} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • EZ Newswire"}} btnLink={"https://app.eznewswire.com/news/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection"} />
              <PressCard cardIcon={P_logo3} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Mid Florida Newspapers"}} btnLink={"https://www.midfloridanewspapers.com/ap/business/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_cce2fdd7-2180-5e8a-9de7-80d7007af7a4.html"} />
              <PressCard cardIcon={P_logo4} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • AP News"}} btnLink={"https://apnews.com/press-release/ez-newswire/hospitality-and-leisure-industry-bb851d092778da616d20837e83928e49"} />
              <PressCard cardIcon={P_logo5} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Kentucky Today"}} btnLink={"https://www.kentuckytoday.com/news/business/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_12f3bf48-ac21-523d-a566-b79f55dd3634.html"} />
              <PressCard cardIcon={P_logo6} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Peru Tribune"}} btnLink={"https://www.perutribune.com/news/regional/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_c4bf2f78-72d2-50ec-923b-dc87f2354fae.html"} />
              <PressCard cardIcon={P_logo7} cardText={{t1:"Voosh.ai Launches Dispute Manager Solution for Restaurant Profit Protection", t2:"30 August 2023 • Full Service Restaurant News"}} btnLink={"https://www.fsrmagazine.com/content/vooshai-launches-dispute-manager-solution-restaurant-profit-protection"} />
              <PressCard cardIcon={P_logo8} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Bluefield Daily Telegraph"}} btnLink={"https://www.bdtonline.com/region/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_ed431b6a-8797-5779-bece-b72c12fb41c2.html"} />
              <PressCard cardIcon={P_logo9} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"03 September  2023 • Food & Beverage Magazine"}} btnLink={"https://www.fb101.com/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/"} />
              <PressCard cardIcon={P_logo10} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Hastings Tribune"}} btnLink={"https://www.hastingstribune.com/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_588ebd9f-38e7-5970-83e8-b56c08628b27.html"} />
              <PressCard cardIcon={P_logo11} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Index-Journal"}} btnLink={"https://www.indexjournal.com/news/business/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_e1f931ce-5cc2-5616-8d1f-c91a3dcd672b.html"} />
              <PressCard cardIcon={P_logo12} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • H-POnline.com"}} btnLink={"https://www.h-ponline.com/news/wire/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_88074886-b5cf-5f42-881e-1a677cef3816.html"} />
              <PressCard cardIcon={P_logo13} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Chronicle-Tribune"}} btnLink={"https://www.chronicle-tribune.com/news/wire/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_d9b60c5e-f5fb-5aa0-85f2-a6d2f4952d15.html"} />
            </div>
        </div>
        <Section6 />
    </div>
  )
}

export default Press