import React from "react";
import Sec1img from "../../assets/images/DC_f11.webp";
import Sec2img from "../../assets/images/DC_f1.webp";

const Section2DC = () => {
  return (
    <div className="s2-container-rm">
      <div className="row f-width m-auto align-items-center">
        <div className={window.innerWidth < 767 ? 'col-lg-7 col-sm-12 order-sm-2' : 'col-lg-7 col-sm-12'}>
          <img
            className="img-fluid"
            src={Sec1img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#9061F9" }}>
              01
            </p>
            <p className="t2">Real-Time Monitoring & Control</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#9061F9" }}
          ></div>
          <p className="s2-dm-bdy-text">
          Stay ahead with instant alerts and the ability to switch your stores on or off from a single dashboard. Downtime Controller seamlessly integrates with top delivery platforms like DoorDash and Uber Eats, ensuring your business remains operational at peak efficiency, no matter the external conditions.
          </p>
        </div>
      </div>
      <div className="row f-width m-auto align-items-center mt-160">
        <div className="col-lg-5 col-sm-12">
          <div className="s-head-dm">
            <p className="t1" style={{ color: "#9061F9" }}>
              02
            </p>
            <p className="t2">Advanced Analytics & Impact Assessment</p>
          </div>
          <div
            className="s2-dm-bdr"
            style={{ backgroundColor: "#9061F9" }}
          ></div>
          <p className="s2-dm-bdy-text">
          Make informed decisions with a detailed analysis of downtime trends and their revenue implications. From understanding the 'why' behind each downtime instance to quantifying its impact, the Downtime Controller transforms data into actionable insights, driving your business towards operational excellence and enhanced profitability.
          </p>
        </div>
        <div className="col-lg-7 col-sm-12">
          <img
            className="img-fluid"
            src={Sec2img}
            alt="Voosh Dispute Manager feature1"
          />
        </div>
      </div>
    </div>
  );
};

export default Section2DC;
