import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
// import P_logo1 from '../../assets/images/press/1.png'
import P_logo2 from '../../assets/images/press/2.png'
// import P_logo3 from '../../assets/images/press/3.png'
import P_logo4 from '../../assets/images/press/4.png'
// import P_logo5 from '../../assets/images/press/5.png'
// import P_logo6 from '../../assets/images/press/6.png'
import P_logo7 from '../../assets/images/press/14.png'
import P_logo8 from '../../assets/images/press/17.png'
import P_logo9 from '../../assets/images/press/19.png'
import BtnArrow from '../../assets/images/right-arrow.svg'
import PressCard from '../../components/PressCard'
import { logGoogleEvent } from '../../firebase'

const Section5A = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        className: 'center',
        centerMode: true,
        centerPadding: '-30px',
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
      // const BG1 ={ backgroundColor: "#0D5798" }
      const BG2 ={ backgroundColor: "#24292D" }
      // const BG3 ={ backgroundColor: "#000000" }
      const BG4 ={ backgroundColor: "#000000" }
      // const BG5 ={ backgroundColor: "#0D5798" }
      // const BG6 ={ backgroundColor: "#fffffa" }
      const BG7 ={ backgroundColor: "#fff" }
      const BG8 ={ backgroundColor: "#373737"}
      const BG9 ={ backgroundColor: "#fd0000" }

  return (
    <div className='press-slider-container'>
        <div className='row f-width m-auto press-slider'>
            <div className='p-s-head'>
                <p className='section-head mb-0'>In the press</p>
                <Link to={'/press'}  onClick={() => {
                    logGoogleEvent("redirect to /press page", {
                      name: "redirect to /press page",
                    });
                  }}>
                    <div className="btn btn-outline-primary btn-with-arrow">
                    View all <img src={BtnArrow} className="ps-2" alt="Right Arrow" />{' '}
                    </div>
                </Link>
            </div>
            <div className='w-1250'>
              <Slider {...settings}>
                  <PressCard cardIcon={P_logo9} cardBG={BG9} cardText={{t1:"Leveraging Viral Moments with Priyam Saraswat of Voosh.ai - Cali BBQ Media - Digital Content & Media", t2:"02 October 2023 • Cali BBQ Media"}} btnLink={"https://content.calibbq.media/leveraging-viral-moments-with-priyam-saraswat-of-voosh-ai/"} />
                  <PressCard cardIcon={P_logo8} cardBG={BG8} cardText={{t1:"The Crucial Role of Dispute Management and Data Consolidation for Restaurants | QSR magazine", t2:"27 September 2023 • QSR magazine"}} btnLink={"https://www.qsrmagazine.com/outside-insights/crucial-role-dispute-management-and-data-consolidation-restaurants"} />
                  <PressCard cardIcon={P_logo7} cardBG={BG7} cardText={{t1:"Tech Tracker: Is social video the future of restaurant reviews? | Voosh now uses AI to respond to customer disputes", t2:"19 September 2023 • Nation's Restaurant News"}} btnLink={"https://www.nrn.com/technology/tech-tracker-social-video-future-restaurant-reviews"} />
                  {/* <PressCard cardIcon={P_logo1} cardBG={BG1} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • WRAL.com"}} btnLink={"https://www.wral.com/story/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/21023902/"} /> */}
                  
                  {/* <PressCard cardIcon={P_logo3} cardBG={BG3} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Mid Florida Newspapers"}} btnLink={"https://www.midfloridanewspapers.com/ap/business/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_cce2fdd7-2180-5e8a-9de7-80d7007af7a4.html"} /> */}
                  <PressCard cardIcon={P_logo4} cardBG={BG4} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • AP News"}} btnLink={"https://apnews.com/press-release/ez-newswire/hospitality-and-leisure-industry-bb851d092778da616d20837e83928e49"} />
                  {/* <PressCard cardIcon={P_logo6} cardBG={BG6} cardText={{t1:"Voosh Launches Dispute Manager: The Game-Changer for Restaurant Profit Protection", t2:"29 August 2023 • Peru Tribune"}} btnLink={"https://www.perutribune.com/news/regional/voosh-launches-dispute-manager-the-game-changer-for-restaurant-profit-protection/article_c4bf2f78-72d2-50ec-923b-dc87f2354fae.html"} /> */}
                  <PressCard cardIcon={P_logo2} cardBG={BG2} cardText={{t1:"Voosh Offers Free 30-Day Trial of Its Revolutionary Restaurant Intelligence Platform", t2:"25 October 2023 • EZ Newswire"}} btnLink={"https://news.eznewswire.com/news/voosh-offers-free-30-day-trial-of-its-revolutionary-restaurant-intelligence-platform"} />
              </Slider>
            </div>
        </div>
    </div>
  )
}

export default Section5A