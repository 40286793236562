import React from 'react'
import '../assets/css/sucess-stories.css'
import HeroSection from '../pages/SucessStories/HeroSection'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>Voosh Privacy Policy</title>
        <meta name='description' content="Voosh values your privacy and is dedicated to safeguarding your personal information. This policy details our approach to data collection, usage, and protection." />
        <meta name='keywords' content='Voosh Privacy Policy,Voosh, Restaurant Marketplace Intelligence Platform, third-party marketplaces, restaurant operators, operational efficiency, customer experience, profitability, 3rd Party Marketplace Optimization, Restaurant Technology, Data-Driven Decisions for Restaurants, Restaurant Operations Efficiency, Restaurant Profitability, Voosh Benefits, Restaurant Profit Maximization, Operational Efficiency, Cost Savings, Data-Driven Decisions' />
      </Helmet>
      <HeroSection contents={{ head: 'Privacy Policy', s_head: '' }} />

      <div className='bg-white f-width m-auto pb-5 mv-overfloX-h'>
        <div className="text-row-ss">
            <p className="rtss2">
            <strong>Voosh</strong> values your privacy and is dedicated to safeguarding your personal information. This policy details our approach to data collection, usage, and protection.
            </p>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Information Collection and Use</p>
            <p className="rtss2">
            We collect information to provide and improve our services to you. This includes:
            </p>
            <ul>
                <li>Personal identifiers like your name and contact details</li>
                <li>Payment and transaction information</li>
                <li>Technical data such as cookies, IP addresses, and usage data</li>
            </ul>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>How We Use Your Information</p>
            <p className='rtss2'>We use the information we collect to:</p>
            <ul>
                <li>Operate and maintain our services</li>
                <li>Understand and analyze how you use our services</li>
                <li>Develop new products, services, features, and functionality</li>
            </ul>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Communicating with You</p>
            <p className='rtss2'>We may use your contact information to communicate with you about product offers, promotions, and alerts, as well as service-related announcements.</p>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Information Sharing and Disclosure</p>
            <p className='rtss2'>Voosh does not share or disclose your personal information to third parties, except as required to provide you with our services, comply with the law, or protect the rights and safety of our company, our users, and the public.</p>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Your Rights</p>
            <p className='rtss2'>You have the right to access the information we hold about you and, where necessary, to ask for it to be corrected or deleted. You can also opt-out of certain data uses and have a right to privacy as a consumer.</p>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Changes to This Policy</p>
            <p className='rtss2'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Contact Us</p>
            <p className='rtss2'>If you have any questions about this Privacy Policy, please contact us at:</p>
            <p className='rtss2'>Email: bilal@voosh.ai</p>
            <p className='rtss2'>Address: 2261, Market Street #4509, San Francisco, CA 94114</p>
        </div>
        <div className="text-row-ss">
            <p className='rtss1'>Effective Date</p>
            <p className='rtss2'>This policy is effective as of 25th December 2023.</p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
