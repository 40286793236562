import React, {useEffect, useState} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import {SSArrays} from './SSData'

const Section2 = () => {
    const [searchItem, setSearchItem] = useState('')
    const [filteredSS, setFilteredSS] = useState(SSArrays)
    const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
    }
    const resetForm = () => {
        setSearchItem('')
    }
    useEffect(() => {
        const filteredItems = SSArrays.filter((blog) =>
        blog.title.toLowerCase().includes(searchItem.toLowerCase())
        );
        setFilteredSS(filteredItems)
    }, [searchItem]);

  return (
    <>
    <div className="s1-container v-blogs">
        <div className="f-width m-auto text-center">
        <div className="row ss-hero-data">
            <h1 className="hero-primary-text ss-h">Success stories</h1>
            <p className="hero-secondary-text ss-sh">Discover How Restaurants Are Maximizing Their Potential with Voosh</p>
            <form className="form">
            <button disabled>
                <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                    <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </button>
            <input className="input" placeholder="Search Success stories" required="" type="text" value={searchItem} onChange={handleInputChange} />
            <button className="reset" type="reset" onClick={() => resetForm()}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>
            </form>
        </div>
        </div>
    </div>
    <div className='ss-section2 container-fluid'>
        <div className='f-width m-auto'>
            <Tabs defaultActiveKey="latest" transition={true} id="anim-tab" className="ss-tabs">
                <Tab eventKey="latest" title="Latest">
                    <div className='row'>
                        {filteredSS.length >= 1 ? filteredSS.map(item => (
                        <div key={item.id} className='col-lg-4 col-md-6 col-sm-12'>
                            <Card className='blog-card'>
                                <Card.Img variant="top" src={item.img} alt='Voosh Blogs thumbnail'/>
                                <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <div className='row ps-2'>
                                    <div className={item.t1 === 'na' ? 'd-none' : 'col ss-card-b1 me-2'}>
                                        <p className='t1 mb-0'>{item.t1}</p>
                                        <p className='t2 mb-0'>{item.t2}</p>
                                    </div>
                                    <div className='col ss-card-b1 me-2'>
                                        <p className='t1 mb-0'>{item.t3}</p>
                                        <p className='t2 mb-0'>{item.t4}</p>
                                    </div>
                                </div>
                                <Link to={item.url}>
                                    <div className='btn btn-outline-primary btn-with-arrow mt-3'><font className="pe-2">Read this story</font>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M21.5 12H3.5H21.5Z" fill="white"/>
                                            <path className='stroke-blue' d="M14.5 5L21.5 12M21.5 12L14.5 19M21.5 12H3.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </Link>
                                </Card.Body>
                            </Card>
                        </div>
                        )): <p>No results.</p> }
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>
    </>
  )
}

export default Section2