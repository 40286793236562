import React from 'react'

const TnC = () => {
  return (
    <div className='TnC-container bg-white'>
        <div className='row f-width m-auto'>
            <h1>Social Media Contest Terms and Conditions</h1>
            <p>This contest ("Contest") is promoted by Voosh.ai ("Promoter"). By participating in this Contest, each entrant agrees to abide by these Terms and Conditions.</p>
            <p><font>Eligibility:</font> The Contest is open to residents of the United States who are at least 18 years of age. Employees of Voosh.ai, their close relatives, or anyone otherwise connected with the organization or judging of the Contest are not eligible to participate.</p>
            <p><font>Entry:</font> No entry fee or purchase is necessary to enter the Contest. To participate, entrants must
                like Voosh on LinkedIn, upload their photo, tag <span className='fw-bold'> @vooshfoods</span>, and use the hashtag
                <span className='fw-bold'> #getvooshed</span>. Only one entry will be accepted per person; multiple entries from the same
                individual will result in disqualification. The closing date for entry submissions is 9/17/23. Entries
                submitted after this date will not be considered. The Promoter assumes no responsibility for
                entries not received for any reason.
            </p>
            <p><font>Prize:</font> The prize for the Contest is a $100 gift card for one of the following delivery apps: Door
                Dash, Uber Eats, or Grub Hub. The prize is non-transferable; no cash or other alternatives will
                be provided. The Promoter reserves the right to substitute the prize with another of equivalent
                value in the event the original prize is unavailable.
            </p>
            <p><font>Winner Selection:</font> The winner will be chosen randomly from all valid entries received and
                verified by the Promoter and/or its agents. The winner will be notified via direct message on
                LinkedIn within 24 hours of the selection, which will be completed by 11:59 PM on 9/18/23. If the
                winner cannot be contacted or does not claim the prize within 14 days of notification, the
                Promoter reserves the right to withdraw the prize and select a replacement winner.
            </p>
            <p><font>General Conditions:</font> The Promoter reserves the right to cancel or amend the Contest and these
                Terms and Conditions without notice in unforeseen circumstances. Any changes will be
                communicated to entrants as soon as possible. The Promoter is not responsible for inaccurate
                prize details provided by any third party in connection with this Contest. By entering this
                Contest, participants indicate their agreement to be bound by these Terms and Conditions. The
                Contest and these Terms and Conditions are governed by US law, and any disputes arising
                from them will be subject to the exclusive jurisdiction of the US courts.
            </p>
            <p><font>Data Protection:</font> The winner agrees to use their name and image in promotional material related
                to the Contest. All personal data relating to participants will be used in accordance with US data
                protection legislation and will not be disclosed to third parties without the individual's prior
                consent.
            </p>
            <p><font>Disclaimer:</font> This Contest is not sponsored, endorsed, or administered by LinkedIn. Entrants
                provide their information to Voosh and not to LinkedIn.
            </p>
        </div>
    </div>
  )
}

export default TnC